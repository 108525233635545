import React, { useEffect, useState } from 'react';
import {
  Button, Modal, Tooltip,
} from 'antd';
import _ from 'lodash';
import {
  DesktopOutlined,
  TableOutlined,
  DatabaseOutlined,
  UnorderedListOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  BorderlessTableOutlined,
  DollarCircleOutlined,
  RocketOutlined,
  ApartmentOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions';
import './Menu.css';
import QuickSearchMenu from './QuickSearchMenu';
import { listModes } from '../Common/GlobalConstants';
import styles from './styles.module.scss';

export default function MainMenu({ isProd }) {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const defaultPlants = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);
  const defaultCCs = useSelector((state) => state.commonState.userSettings?.DEFAULT_COMPANY_CODES);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const [quicksearchVisible, setQuicksearchVisible] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);

  const clearMaterialState = (mode) => {
    if (mode === listModes.matList) {
      dispatch(allActions.MaterialListActions.clearMaterialStoreState());
      dispatch(allActions.MaterialListActions.selectView(userSettings?.DEFAULT_USER_VIEW));
    } else if (mode === listModes.mmList) {
      dispatch(allActions.MMActions.clearMMStoreState());
      dispatch(allActions.MaterialListActions.selectView(userSettings?.DEFAULT_MM_USER_VIEW));
    }
  };

  const history = useHistory();
  const [quickSearchText, setQuickSearchText] = useState([]);
  const [searchMMR, setSearchMMR] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedCCs, setSelectedCCs] = useState([]);
  const [funcLocations, setFuncLocations] = useState([]);
  const [storageBins, setStorageBins] = useState([]);
  const [contractNos, setContractNos] = useState([]);
  const [allMaterials, setAllMaterials] = useState(false);
  const [error, setError] = useState({
    material: false,
    contractNo: false,
  });
  const [searchMenuOpened, setSearchMenuOpened] = useState(false);
  const [searchOperators, setSearchOperators] = useState({
    keywords: 'Contains',
    funcLoc: 'EqualTo',
    storageBin: 'EqualTo',
    contractNos: 'EqualTo',
    plant: 'EqualTo',
    companyCode: 'EqualTo',
    material: 'EqualTo',
  });

  const handleOk = () => {
    if ((defaultPlants?.length > 0 && !_.isEqual(selectedPlants?.sort(), defaultPlants?.sort()))
      || (defaultCCs?.length > 0 && !_.isEqual(selectedCCs?.sort(), defaultCCs?.sort()))) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(false));
    }
    setQuicksearchVisible(false);
    const plantList = selectedPlants?.filter((x) => typeof JSON.parse(x) !== 'object');
    dispatch(allActions.CommonActions.setQuickSearchObject({
      quickSearchText,
      searchMMR,
      selectedPlants: plantList,
      selectedCCs,
      funcLocations,
      storageBins,
      contractNos,
      searchOperators,
      allMaterialsInContract: allMaterials,
    }));
    history.push('/material-list');
  };

  const checkForError = () => Object.values(error).some((item) => item);

  useEffect(() => {
    setSearchMenuOpened(quicksearchVisible);
  }, [quicksearchVisible]);

  return (
    <>
      <Modal
        className="quickSearchModal"
        wrapClassName="quickSearchModalPlacement"
        title="Quick Search"
        onOk={handleOk}
        onCancel={() => setQuicksearchVisible(false)}
        visible={quicksearchVisible}
        okText="Search"
        cancelText="Cancel"
        okButtonProps={{ disabled: error }}
        footer={[
          <Button key="ResetFlds" type="primary" onClick={() => setResetSearch(true)}>Reset all fields</Button>,
          <Button key="Search" type="primary" onClick={handleOk} disabled={checkForError()}>Search</Button>,
          <Button key="Cancel" onClick={() => setQuicksearchVisible(false)}>Cancel</Button>,
        ]}
      >
        <QuickSearchMenu
          searchMenuOpened={searchMenuOpened}
          setQuickSearchText={setQuickSearchText}
          quickSearchText={quickSearchText}
          searchMMR={searchMMR}
          setSearchMMR={setSearchMMR}
          selectedPlants={selectedPlants}
          setSelectedPlants={setSelectedPlants}
          selectedCCs={selectedCCs}
          setSelectedCCs={setSelectedCCs}
          funcLocations={funcLocations}
          setFuncLocations={setFuncLocations}
          storageBins={storageBins}
          setStorageBins={setStorageBins}
          contractNos={contractNos}
          setContractNos={setContractNos}
          error={error}
          setError={setError}
          searchOperators={searchOperators}
          setSearchOperators={setSearchOperators}
          resetSearch={resetSearch}
          setResetSearch={setResetSearch}
          allMaterials={allMaterials}
          setAllMaterials={setAllMaterials}
        />
      </Modal>
      <div
        className={styles.menu}
        style={{ backgroundColor: isProd ? 'var(--prod-color)' : 'var(--non-prod-color)' }}
      >
        <Tooltip placement="right" title="Quick Search">
          <div key="quicksearch" onClick={() => setQuicksearchVisible(true)} className={`${styles.menuItem} ${styles.asButton}`}>
            <SearchOutlined />
            <span className={styles.linkText}>Quick Search</span>
          </div>
        </Tooltip>
        <Tooltip placement="right" title="Dashboard">
          <NavLink to="/dashboard" key="/dashboard" className={`${styles.menuItem} ${location === '/dashboard' ? styles.active : ''}`}>
            <DesktopOutlined />
            <span className={styles.linkText}>Dashboard</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Material List">
          <NavLink to="/material-list" key="/material-list" className={`${styles.menuItem} ${location === '/material-list' || location.includes('material-details') ? styles.active : ''}`} onClick={() => clearMaterialState(listModes.matList)}>
            <TableOutlined />
            <span className={styles.linkText}>Material List</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Material Master">
          <NavLink to="/material-master" key="/material-master" className={`${styles.menuItem} ${location === '/material-master' ? styles.active : ''}`} onClick={() => clearMaterialState(listModes.mmList)}>
            <BorderlessTableOutlined />
            <span className={styles.linkText}>Material Master</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Dynamic Work Lists">
          <NavLink to="/work-lists" key="/work-lists" className={`${styles.menuItem} ${location === '/work-lists' ? styles.active : ''}`}>
            <UnorderedListOutlined />
            <span className={styles.linkText}>Dynamic WL</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Static Work Lists">
          <NavLink to="/static-work-lists" key="/static-work-lists" className={`${styles.menuItem} ${location === '/static-work-lists' ? styles.active : ''}`}>
            <DatabaseOutlined />
            <span className={styles.linkText}>Static WL</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Mass Update">
          <NavLink to="/mass-update" key="/mass-update" className={`${styles.menuItem} ${location === '/mass-update' ? styles.active : ''}`}>
            <RocketOutlined />
            <span className={styles.linkText}>Mass Update</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="New Stock Addition">
          <NavLink to="/simulation/new-stock" className={`${styles.menuItem} ${location === '/simulation/new-stock' ? styles.active : ''}`}>
            <LineChartOutlined />
            <span className={styles.linkText}>New Stock</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        {/* <Menu.Item key="/simulation/scenario" disabled icon={<DotChartOutlined />}>
          <NavLink to="/simulation/scenario">Scenario Simulation</NavLink>
        </Menu.Item> */}
        <Tooltip placement="right" title="Simulation Results">
          <NavLink to="/simulations" className={`${styles.menuItem} ${location === '/simulations' ? styles.active : ''}`}>
            <MenuUnfoldOutlined />
            <span className={styles.linkText}>Simulations</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Monetary Impact">
          <NavLink to="/monetary-impact" key="/monetary-impact" className={`${styles.menuItem} ${location === '/monetary-impact' ? styles.active : ''}`}>
            <DollarCircleOutlined />
            <span className={styles.linkText}>Mon. Impact</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </NavLink>
        </Tooltip>
        <Tooltip placement="right" title="Material Tracking">
          <a
            target="_blank"
            href="https://p03web.statoil.no/sap/bc/ui5_ui5/sap/zpsmm_mat_track/index.html"
            className={styles.menuItem}
            rel="noreferrer"
          >
            <EnvironmentOutlined />
            <span className={styles.linkText}>Mat. Tracking</span>
            <div className={styles.indicator} />
            <div className={styles.bottomDivider} />
          </a>
        </Tooltip>
        <Tooltip placement="right" title="Virtual Inventory">
          <a target="_blank" href="https://service.collabor8.no/mm/app/#/vi/home" rel="noreferrer" className={styles.menuItem}>
            <ApartmentOutlined />
            <span className={styles.linkText}>Virtual Inv.</span>
            <div className={styles.indicator} />
          </a>
        </Tooltip>
        {/* <Menu.Item key="/feature-timeline" icon={<PullRequestOutlined />}>
          <NavLink to="/feature-timeline">Feature Timeline</NavLink>
        </Menu.Item> */}
      </div>
    </>
  );
}
