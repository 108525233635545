/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Popover, Switch, Tooltip, Button, Badge,
} from 'antd';
import allActions from '../../actions';
import {
  showNotification,
  storeNotifKey,
  clearNotification,
} from '../../actions/utilities/Notifications/NotificationActions';
import UserPanel from './UserPanel';
import { NOTIFICATION_TYPES, NUMBER_OF_NOTIFICATIONS_TO_FETCH } from '../Common/GlobalConstants';
import { formatMaterialNumber } from '../Common/DateAndNumberFunctions';
import styles from './styles.module.scss';

const UserMenuContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState.user);
  const notificationCount = useSelector((state) => state.commonState.notificationCount);
  const notificationKeys = useSelector((state) => state.notificationState?.notificationKeys);
  const notifications = useSelector((state) => state.commonState.notifications);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const hideDone = useSelector((state) => state.commonState.hideDoneNotifications);
  const [userPanel, setUserPanel] = useState(false);
  const [initials, setInitials] = useState();
  const [notificationTypes, setNotificationTypes] = useState(null);
  const [notificationSub, setNotificationSub] = useState(null);
  const notificationData = useSelector((state) => state.notificationState?.notificationData);
  const [toBeNotified, setToBeNotified] = useState([]);
  const [offset, setOffset] = useState(0);
  const typesRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    typesRef.current = notificationTypes;
  });

  useEffect(() => {
    if (userSettings?.HIDE_NOTIFICATIONS) {
      try {
        const hidden = JSON.parse(userSettings.HIDE_NOTIFICATIONS);
        const types = Object.values(NOTIFICATION_TYPES).filter((type) => !hidden.includes(type));
        setNotificationTypes(types);
      } catch { /* */ }
    } else {
      setNotificationTypes(Object.values(NOTIFICATION_TYPES));
    }
  }, [userSettings?.HIDE_NOTIFICATIONS]);

  const loadNotifications = () => {
    dispatch(allActions.CommonActions.getNotifications(
      user?.uniqueId,
      notificationTypes,
      NUMBER_OF_NOTIFICATIONS_TO_FETCH + offset,
      null,
      hideDone,
    ));
  };

  useEffect(() => {
    if (typeof notificationCount?.unread === 'number' && notificationTypes) {
      loadNotifications(hideDone);
    }
  }, [notificationCount?.unread, hideDone, notificationTypes]);

  useEffect(() => {
    if (user?.uniqueId && notificationTypes) {
      try {
        const hidden = userSettings?.HIDE_NOTIFICATIONS
          ? JSON.parse(userSettings.HIDE_NOTIFICATIONS)
          : [];
        const types = Object.values(NOTIFICATION_TYPES).filter((type) => !hidden.includes(type));
        dispatch(allActions.CommonActions.checkNotifications(
          user?.uniqueId, types,
        ));
        loadNotifications();

        // create interval function to check for notifications
        if (notificationSub) clearInterval(notificationSub);
        setNotificationSub(
          setInterval(() => {
            dispatch(allActions.CommonActions.checkNotifications(
              user?.uniqueId, typesRef.current,
            ));
          }, 60000),
        );
      } catch { /* */ }
    }
  }, [user?.uniqueId, notificationTypes]);

  useEffect(() => {
    if (userSettings?.HIDE_NOTIFICATIONS && notificationSub) {
      dispatch(allActions.CommonActions.clearNotifications());
    }
  }, [userSettings?.HIDE_NOTIFICATIONS]);

  const showNewNotification = () => {
    if (toBeNotified?.length) {
      const [topNotification, ...restNotifications] = toBeNotified;
      if (topNotification) {
        dispatch(storeNotifKey(topNotification?.Key));
        dispatch(showNotification(topNotification));
      }
      setToBeNotified(restNotifications);
    }
  };

  // To notify user about simulation completion
  useEffect(() => {
    const toBeNotifiedList = [];
    notifications?.forEach((notification) => {
      if (notification?.accepted === false
        && (notification?.type === 'SimComplete' || notification?.type === 'SimFailed')
        && notification?.viewed !== true) {
        let params;
        try {
          let isRendered;
          if (notification?.type === 'SimComplete') {
            isRendered = notificationKeys?.find((key) => 'simSuccess'.concat(notification?.id) === key);
          } else {
            isRendered = notificationKeys?.find((key) => 'simFailed'.concat(notification?.id) === key);
          }
          if (isRendered) return;
          params = JSON.parse(notification.parameters);
          const simType = params?.type;
          const materials = params?.material.split(',').map((m) => formatMaterialNumber(m));
          let msg = simType === 'multi' ? 'Scenario ' : 'Single material ';
          msg = msg.concat(notification?.type === 'SimFailed' ? 'simulation failed' : 'simulation complete');
          msg = msg.concat('\nPlant: ', params?.plant, '\nMaterial', materials?.length > 1 ? 's ' : ' ');
          if (materials?.length < 5 && materials?.length > 1) {
            msg = msg.concat(materials?.join(', '));
          } else if (materials?.length === 1) {
            msg = msg.concat(materials?.[0]);
          } else {
            msg = materials?.length > 4 ? 'multiple' : '';
          }
          // eslint-disable-next-line prefer-template
          const url = '/simulations/' + params?.runid;
          const msgContainer = notification?.type === 'SimComplete' ? (
            <div>
              {msg}
              {'\n'}
              Click to go to
              {' '}
              <a onClick={() => history.push(url)} style={{ cursor: 'pointer' }}>simulation results</a>
            </div>
          ) : (<div>{msg}</div>);
          const key = notification?.type === 'SimComplete' ? 'simSuccess'.concat(notification?.id) : 'simFailed'.concat(notification?.id);
          const newNotif = {
            Key: key,
            Type: notification?.type === 'SimComplete' ? 'simSuccess' : 'simFailed',
            Title: notification?.text,
            Message: msgContainer,
            onClose: () => {
              dispatch(clearNotification());
              dispatch(allActions.CommonActions.markNotifAsViewed(
                notification?.id,
                user?.uniqueId,
                notificationTypes,
              ));
            },
          };
          toBeNotifiedList.push(newNotif);
        } catch (e) {
          console.log(e);
          console.log(notification.id);
        }
      }
    });
    if (toBeNotifiedList?.length) {
      setToBeNotified(toBeNotifiedList);
      // showNewNotification();
    }
  }, [notifications]);

  useEffect(() => {
    showNewNotification();
  }, [notificationData?.Message, toBeNotified]);

  useEffect(() => {
    if (!user?.account?.name) return;
    try {
      const names = user.account.name.split(' ');
      const ini = names.map((n, i) => (i === 0 || i === names.length - 1) && n[0]).filter((n) => n).join('');
      setInitials(ini);
    } catch (e) {
      setInitials('N/A');
    }
  }, [user]);

  const acceptAllNotifications = () => {
    dispatch(allActions.CommonActions.acceptAllNotifications(user?.uniqueId, notificationTypes));
  };

  const changeHideDone = (checked) => {
    setOffset(0);
    dispatch(allActions.CommonActions.changeHideDone(checked));
  };

  const header = (userName) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }} className={styles.userHeader}>
      <span>{userName ?? 'User Panel'}</span>
      <span>
        <Tooltip title="Hide all complete notifications" placement="bottom">
          <Switch size="small" onChange={changeHideDone} />
        </Tooltip>
      </span>
      <span>
        <Button size="small" onClick={() => acceptAllNotifications()} disabled={notificationCount < 1}>
          Mark all as done
        </Button>
      </span>
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      title={header(user?.account?.name)}
      visible={userPanel}
      onVisibleChange={(visible) => setUserPanel(visible)}
      trigger="click"
      content={(
        <UserPanel
          notifications={notifications}
          notificationCount={notificationCount}
          userID={user?.uniqueId}
          close={() => setUserPanel(false)}
          notificationTypes={notificationTypes}
          hideDone={hideDone}
          offset={offset}
          setOffset={setOffset}
        />
      )}
    >
      <Badge count={notificationCount?.unread ?? 0} offset={[-38, 38]}>
        <Button shape="circle" size="large">
          {initials || 'N/A'}
        </Button>
      </Badge>
    </Popover>
  );
};

export default UserMenuContainer;
