/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Input, Button, Tooltip, Col, Row, Tag, Select, DatePicker,
} from 'antd';
import moment from 'moment';
import {
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  PlusCircleOutlined,
  MinusOutlined,
  FilterFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { SAP_URL } from '../../../actions/APIUrls';
import {
  boolOptions, getTypeOptionArray, dateOptions, getBoolOptions,
  getOption, numberOptions, stringOptions, getBitOptions, dropdownOptions,
} from './HeaderComponents/AdvancedSearch/AdvancedSearchOptions';
import {
  isInteger, getConvertedPrice, displayRelevantDecimals, localDateFormat,
  timeString,
} from '../../Common/DateAndNumberFunctions';
import './MaterialListView.css';
import {
  recommendationValues, sensitiveValues, currencyValues, currencyCols, unFilterableCols, columnsWithAggregation,
} from '../../Common/SpecialValues';
import { statusIndicator } from '../MaterialDetails/components/helpers';

const { Option } = Select;

const modes = {
  STATIC: 'StaticWorklist',
  DYNAMIC: 'DynamicWorklist',
  null: '',
};

const mmDefaultWidths = {
  CROSS_PLANT_MATERIAL_STATUS: 50,
  MATERIAL_TYPE_DELETION_IND: 50,
  MATERIAL_TYPE_SAP_ID: 84,
  EN_MATERIAL_TYPE_NM: 174,
  BASE_UNIT_OF_MEASURE_SAP_ID: 50,
  MANUFACTURER_COMPANY_SAP_ID: 78,
  MANUFACTURER_MATERIAL_TYPE_SAP_CD: 108,
  MFGR_PARTNO_STRIPPED_: 64,
  EXTENDED_PARTNO: 126,
  NORSOK_NAME_: 102,
  EDAM_MATERIAL_TYPE_CREATED_DT: 80,
  LAST_CHANGED_DATE: 93,
  MATERIAL_CLASS_SAP_CD: 61,
  POtextEN: 102,
  internalCommentEN: 150,
  PRODUCT_SERVICE_GROUP_SAP_CD: 78,
  PRODUCT_SERVICE_GROUP_SAP_NM: 108,
  SEGMENT: 84,
  TYPE_: 90,
  QM_PROCUREMENT_ACTIVE: 102,
};

const handleClickSort = (cell, searchFilters, onChangeFilterSort, direction, confirm) => {
  onChangeFilterSort(
    {},
    searchFilters,
    { field: cell.ORIGINAL_NAME, order: direction },
    { action: 'sort' },
  );
  confirm();
};

const getColDataTypes = (filters, materialListColumns) => {
  const dataTypes = {};
  if (!filters) return dataTypes;
  Object.keys(filters)?.forEach((x) => {
    dataTypes[x] = materialListColumns?.find((col) => col.ORIGINAL_NAME === x)?.DATA_TYPE;
  });
  return dataTypes;
};

const sortButton = (cell, searchFilters, onChangeFilterSort, direction, confirm, wide) => (
  <Button
    size="small"
    style={{ width: wide ? '50%' : '38%' }}
    onClick={() => handleClickSort(
      cell,
      searchFilters,
      onChangeFilterSort,
      direction,
      confirm,
    )}
  >
    {direction === 'ascend' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
    {direction === 'ascend' ? 'Sort A-Z' : 'Sort Z-A'}
  </Button>
);

const columnTypes = {
  DATE: 'date',
  STRING: 'string',
  INT: 'int',
  FLOAT: 'float',
  SELECT: 'select',
  BOOLEAN: 'boolean',
};

const getFilterDropdown = (
  columns, dropdownValues, cell, searchFilters, onChangeFilterSort, mode, defaultPlantsActive, isMMCols, isStaticWL,
) => ({
  filterDropdown: ({
    // filterDropdown props documentation:
    // https://github.com/ant-design/ant-design/blob/ecc54dda839619e921c0ace530408871f0281c2a/components/table/interface.tsx#L79
    // eslint-disable-next-line react/prop-types
    setSelectedKeys, selectedKeys, confirm,
  }) => {
    const dataIndex = cell.ORIGINAL_NAME;
    const [input, setInput] = useState('');
    const [inputInvalid, setInputInvalid] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [type, setType] = useState();
    const [operator, setOperator] = useState('EqualTo');
    const [dropdownList, setDropdownList] = useState([]);

    const getType = (col) => columns?.find((x) => x.ORIGINAL_NAME === col)?.DATA_TYPE;

    useEffect(() => {
      if (dropdownValues) {
        if (dataIndex in dropdownValues) {
          setType(columnTypes.SELECT);
        } else {
          const dataType = getType(dataIndex) ?? 'string';
          if (['bigint', 'int'].includes(dataType)) {
            setType(columnTypes.INT);
          } else if (['decimal', 'float'].includes(dataType)) {
            setType(columnTypes.FLOAT);
          } else if (dataType.toString().includes('date')) {
            setType(columnTypes.DATE);
            if (selectedKeys?.length > 0) setSelectedDate(moment(selectedKeys[0]));
          } else if (dataType === 'bit') {
            setType(columnTypes.BOOLEAN);
          } else {
            setType(columnTypes.STRING);
          }
        }
      }
    }, [dropdownValues]);

    useEffect(() => {
      if (dropdownValues && columnTypes?.SELECT === 'select' && dataIndex) {
        const sortedList = dropdownValues
          ? dropdownValues[dataIndex]?.sort((a, b) => {
            if (isInteger(a.DROPDOWN_VALUE) && isInteger(b.DROPDOWN_VALUE)) {
              return a.DROPDOWN_VALUE - b.DROPDOWN_VALUE;
            }
            return a.DROPDOWN_VALUE > b.DROPDOWN_VALUE ? 1 : -1;
          })
          : dropdownValues[dataIndex];
        setDropdownList(sortedList ? [...sortedList] : sortedList);
      }
    }, [dropdownValues, dataIndex]);

    useEffect(() => {
      if (searchFilters?.[dataIndex]?.[0]?.FilterOperator) {
        setOperator(searchFilters[dataIndex][0].FilterOperator);
      } else {
        setOperator('EqualTo');
      }
    }, [searchFilters]);

    const checkNumberFormat = (n) => {
      if (type === columnTypes.FLOAT) {
        return !Number.isNaN(Number(n));
      }
      if (type === columnTypes.INT) {
        return isInteger(n);
      }
      return true;
    };

    const removeKey = (val) => {
      const newKeys = selectedKeys.filter((v) => v !== val);
      const invalid = newKeys.some((k) => !checkNumberFormat(k));
      setInputInvalid(invalid);
      setSelectedKeys(newKeys);
    };

    /**
     * Execute a search with the current selectedKeys or an updated value array given.
     * Adds the value currently in input if the format is correct.
     * Scrubs duplicate values.
     * @param {Array} recentKeys search values to use instead of state selectedKeys,
     * e.g. if something has been added after last state change.
     */
    const search = (recentKeys = null) => {
      let values = [...new Set(recentKeys ?? selectedKeys)];
      if (input?.length > 0) {
        if (checkNumberFormat(input)) {
          values = [...new Set([...values, input])];
          setInput('');
          setInputInvalid(false);
        }
      }
      const newFilters = _.clone(searchFilters);
      if (newFilters[dataIndex]?.length > 0) {
        newFilters[dataIndex][0].FilterOperator = operator;
        newFilters[dataIndex][0].FilterValue = values;
      } else {
        newFilters[dataIndex] = [{
          ColumnName: dataIndex,
          FilterOperator: operator,
          FilterValue: values,
        }];
      }

      if (newFilters[dataIndex][0].FilterValue?.length < 1) delete newFilters[dataIndex];
      onChangeFilterSort(
        null,
        newFilters,
        null,
        { action: 'search' },
      );
      confirm();
    };

    const onPressEnter = (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.target.blur();
        search();
      }
    };

    const clearFilter = () => {
      const newFilters = _.clone(searchFilters);
      delete newFilters[dataIndex];
      setInput('');
      setSelectedDate();
      onChangeFilterSort(
        null,
        newFilters,
        null,
        { action: 'search' },
      );
      confirm();
    };

    const sortOptions = () => {
      if (!dropdownList) {
        return;
      }
      const sortedList = dropdownList?.sort((a, b) => {
        const aSearch = selectedKeys?.find((x) => a.DROPDOWN_VALUE === x);
        const bSearch = selectedKeys?.find((x) => b.DROPDOWN_VALUE === x);
        if ((aSearch && bSearch) || (!aSearch && !bSearch)) {
          if (isInteger(a.DROPDOWN_VALUE) && isInteger(b.DROPDOWN_VALUE)) {
            return a.DROPDOWN_VALUE - b.DROPDOWN_VALUE;
          }
          return a.DROPDOWN_VALUE > b.DROPDOWN_VALUE ? 1 : -1;
        }
        if (aSearch) {
          return -1;
        }
        return 1;
      });
      setDropdownList(sortedList.length ? [...sortedList] : dropdownList);
    };

    /**
     * Add a search value if it conforms to column format, optionally execute search
     * @param {bool} executeSearch whether to execute a search after adding the current value from
     * input
     */
    const addSearchValue = (executeSearch = false) => {
      const allowMultiple = !!getTypeOptionArray(type).find((x) => x.value === operator)?.multiple;
      if (input?.length === 0 && (!selectedKeys?.length || selectedKeys?.length === 0)) return;
      let values = [...selectedKeys];
      if (input?.length > 0) {
        if (checkNumberFormat(input)) {
          values = [...new Set([...selectedKeys, input])];
          setInput('');
          setInputInvalid(false);
        }
      }
      setSelectedKeys(allowMultiple ? values : [input]);
      if (executeSearch && values?.length > 0) search(values);
    };

    const changeInput = (val) => {
      setInput(val);
      setInputInvalid(
        val?.length > 0
        && !checkNumberFormat(val),
      );
    };

    const updateOperator = (newOperator) => {
      if (!getTypeOptionArray(type).find((x) => x.value === newOperator)?.multiple
        && selectedKeys?.length > 0) {
        setSelectedKeys([]);
      }
      setOperator(newOperator);
    };

    const getOperatorSelector = () => {
      switch (type) {
        case columnTypes.SELECT:
          return (
            <Select
              style={{ width: '100%', marginBottom: '8px' }}
              placeholder="Select condition"
              onChange={updateOperator}
              value={operator}
            >
              {dropdownOptions.map((o) => getOption(o.value, o.display, o.symbol, o.disabled))}
            </Select>
          );
        case columnTypes.DATE:
          return (
            <Select
              style={{ width: '100%', marginBottom: '8px' }}
              placeholder="Select condition"
              onChange={updateOperator}
              value={operator}
            >
              {dateOptions.map((o) => getOption(o.value, o.display, o.symbol, o.disabled))}
            </Select>
          );
        case columnTypes.INT:
        case columnTypes.FLOAT:
          return (
            <Select style={{ width: '100%', marginBottom: '8px' }} placeholder="Select condition" onChange={updateOperator} value={operator}>
              {numberOptions.map((o) => getOption(o.value, o.display, o.symbol, o.disabled))}
            </Select>
          );
        case columnTypes.BOOLEAN:
          return (
            <Select style={{ width: '100%', marginBottom: '8px' }} placeholder="Select condition" onChange={updateOperator} value={operator}>
              {boolOptions.map((o) => getOption(o.value, o.display, o.symbol, o.disabled))}
            </Select>
          );
        case columnTypes.STRING:
        default:
          return (
            <Select style={{ width: '100%', marginBottom: '8px' }} placeholder="Select condition" onChange={updateOperator} value={operator}>
              {stringOptions.map((o) => getOption(o.value, o.display, o.symbol, o.disabled))}
            </Select>
          );
      }
    };

    const setDate = (date) => {
      setSelectedKeys([date.format('YYYY-MM-DD')]);
      setSelectedDate(date);
    };

    const pasteValue = (key, event) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('text');
      const splitText = pastedText && pastedText.split('\r\n').map((item) => item.trim()).filter((s) => s.length > 0);
      if (!splitText || splitText.length < 1) return;
      const newKeys = [...new Set([...selectedKeys, ...splitText])];
      const invalid = newKeys.some((k) => !checkNumberFormat(k));
      setInputInvalid(invalid);
      setSelectedKeys(newKeys);
    };

    const getInputComponent = () => {
      switch (type) {
        case columnTypes.SELECT: return (
          <Tooltip
            title={dataIndex === 'PLANT_FACILITY_SAP_ID' && defaultPlantsActive ? 'Default plant filter enabled' : null}
            placement="bottom"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Select option"
              value={selectedKeys}
              onSelect={(e) => setSelectedKeys([...selectedKeys, e])}
              onDeselect={(e) => removeKey(e)}
              onClear={() => setSelectedKeys([])}
              onInputKeyDown={(e) => onPressEnter(e)}
              onPasteCapture={(e) => pasteValue('input', e)}
              style={{ width: '100%' }}
              disabled={dataIndex === 'PLANT_FACILITY_SAP_ID' && defaultPlantsActive}
              maxTagCount={2}
              maxTagPlaceholder={(
                <Tooltip
                  title={selectedKeys?.length > 0 && selectedKeys.map((val) => `${val}`).join('\r\n')}
                  overlayClassName="selectorTooltip"
                >
                  {`+ ${(selectedKeys?.length ?? 0) - 2}`}
                </Tooltip>
              )}
              onDropdownVisibleChange={() => sortOptions()}
            >
              {
                dropdownList?.map((x) => (
                  <Option value={x.DROPDOWN_VALUE} key={x.DROPDOWN_VALUE}>
                    {x.DROPDOWN_VALUE}
                  </Option>
                ))
              }
            </Select>
          </Tooltip>
        );
        case columnTypes.INT:
        case columnTypes.FLOAT:
        case columnTypes.STRING: return (
          <Tooltip title={inputInvalid ? 'Invalid number input' : null}>
            <Row>
              <Col span={20}>
                <Input
                  className={inputInvalid ? 'invalidInput' : null}
                  placeholder={`Search ${cell.DISPLAY_NAME_LONG ?? cell.DISPLAY_NAME ?? cell.ORIGINAL_NAME}`}
                  onChange={(e) => changeInput(e.target.value)}
                  onPressEnter={() => addSearchValue(true)}
                  onPasteCapture={(e) => pasteValue('input', e)}
                  value={input}
                />
              </Col>
              <Col span={4}>
                <Tooltip title={inputInvalid ? null : 'Add more key words'} placement="bottom">
                  <Button onClick={() => addSearchValue()} disabled={inputInvalid}>
                    <PlusCircleOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Tooltip>
        );
        case columnTypes.DATE: return (
          <DatePicker
            style={{ width: '100%' }}
            format="DD-MM-YYYY"
            value={selectedDate}
            onChange={(date) => setDate(date)}
          />
        );
        case columnTypes.BOOLEAN:
          if (dataIndex === 'MATERIAL_TYPE_DELETION_IND') {
            return (
              <Select
                placeholder="Select option"
                value={selectedKeys.sort((a, b) => b - a)}
                onChange={(_e, option) => setSelectedKeys(option.map((x) => x.value))}
                onDeselect={(e) => removeKey(e)}
                onClear={() => setSelectedKeys([])}
                onInputKeyDown={(e) => (e.key === 'Enter' && search())}
                style={{ width: '100%' }}
                mode="multiple"
              >
                {getBitOptions()}
              </Select>
            );
          }
          return (
            <Select
              allowClear
              placeholder="Select option"
              value={selectedKeys}
              onSelect={(e) => setSelectedKeys([e])}
              onDeselect={(e) => removeKey(e)}
              onClear={() => setSelectedKeys([])}
              onInputKeyDown={(e) => (e.key === 'Enter' && search())}
              style={{ width: '100%' }}
            >
              {getBoolOptions()}
            </Select>
          );

        default: return null;
      }
    };
    const summarizable = columnsWithAggregation.includes(cell.ORIGINAL_NAME) && !isMMCols && !isStaticWL;
    return (
      <Col style={{
        padding: '8px', width: '15vw', minWidth: 200, maxWidth: 250,
      }}
      >
        <Row justify="space-between" style={{ marginBottom: '8px' }}>
          {sortButton(cell, searchFilters, onChangeFilterSort, 'ascend', confirm, !summarizable)}
          {sortButton(cell, searchFilters, onChangeFilterSort, 'descend', confirm, !summarizable)}
          {summarizable
            && (
            <Tooltip
              placement="bottomLeft"
              title="Summarize column values"
            >
              <Button
                size="small"
                style={{ width: '24%', fontWeight: '500' }}
                onClick={() => onChangeFilterSort(
                  {},
                  searchFilters,
                  { field: [cell.ORIGINAL_NAME] },
                  { action: 'aggregate' },
                )}
              >
                Σ
              </Button>
            </Tooltip>
            )}
        </Row>
        { // hide filter menu if multiple filters on column or in static worklist mode,
          // if multiple filters, user must use Adv.Search
          mode !== modes.STATIC
          && (!searchFilters[dataIndex]?.length
            || searchFilters[dataIndex]?.length < 2)
          && (
            <>
              <Row style={{ marginBottom: '8px' }} justify="space-between">
                <Button
                  type="primary"
                  onClick={() => search()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: '50%' }}
                  disabled={inputInvalid}
                >
                  Search
                </Button>
                <Button
                  onClick={() => clearFilter()}
                  size="small"
                  style={{ width: '50%' }}
                >
                  Reset
                </Button>
              </Row>
              <Row>
                {getOperatorSelector()}
              </Row>
              <Row style={{ marginBottom: '8px' }}>
                {getInputComponent()}
              </Row>
              <Row>
                {(type === columnTypes.INT || type === columnTypes.FLOAT || type === columnTypes.STRING)
                  && selectedKeys?.length > 0 && selectedKeys.map((val) => {
                  const valid = checkNumberFormat(val);
                  return (
                    <Tag
                      style={{ marginBottom: '4px' }}
                      key={val}
                      closable
                      color={valid
                        ? 'blue'
                        : 'red'}
                      icon={valid
                        ? ''
                        : <ExclamationCircleOutlined />}
                      onClose={() => removeKey(val)}
                    >
                      {val}
                    </Tag>
                  );
                })}
              </Row>
            </>
          )
        }
        {searchFilters[dataIndex]?.length
          && searchFilters[dataIndex].length > 1
          && (
            <Row style={{ padding: '8px' }}>
              Multiple filters are active on this column. Use Advanced Search to edit.
            </Row>
          )}
      </Col>
    );
  },
});

const getFirstValidString = (strings) => strings.find((string) => string !== null && string.trim() !== '');

const columnTitle = (cell) => {
  const params = [cell.DISPLAY_NAME_LONG, cell.DISPLAY_NAME, cell.ORIGINAL_NAME];
  return (
    <>
      <Tooltip
        placement="bottomLeft"
        title={getFirstValidString(params)}
      >
        {getFirstValidString([cell.DISPLAY_NAME, cell.ORIGINAL_NAME])}
      </Tooltip>
    </>
  );
};

const getToolTipForText = (txt, colType) => {
  if ((colType !== 'nvarchar' && colType !== 'varchar') || !txt) return txt;
  const tempText = txt.split('\n');
  return (
    <>
      {tempText.map((x) => (
        <>
          {x}
          <br />
        </>
      ))}
    </>
  );
};

const createColumnHeaderCell = (
  columns,
  dropdownValues,
  cell,
  searchFilters,
  mode,
  updateWidth,
  onChangeFilterSort,
  userView,
  defaultPlantsActive,
  canViewPrices,
  currency = {},
  isMMCols = false,
  isStaticWL = false,
) => {
  let cellHead = {};
  const render = (text, record) => {
    if (recommendationValues.includes(cell.ORIGINAL_NAME) && !record?.DISPLAY_REC_IND) {
      return '';
    }
    if (!canViewPrices && sensitiveValues.includes(cell.ORIGINAL_NAME)) {
      return '';
    }
    let displayText = text;
    if (displayText === undefined) {
      displayText = null;
    }
    const parsedText = Number.parseFloat(displayText);
    const isCurrency = currencyValues.includes(cell.ORIGINAL_NAME);
    if (isCurrency) {
      if (!Number.isNaN(parsedText) && currency?.defaultCurrency && currency?.defaultCurrency !== 'NOK') {
        displayText = getConvertedPrice(
          parsedText, currency?.FROM_RATIO, currency?.TO_RATIO, currency?.EXCHANGE_RATE,
        );
      }
    }
    if (currency?.defaultCurrency && currency?.defaultCurrency !== 'NOK') {
      if (currencyCols.includes(cell.ORIGINAL_NAME)) {
        displayText = currency.defaultCurrency;
      }
    }
    if (cell.DATA_TYPE === 'decimal') {
      displayText = displayRelevantDecimals(displayText, isCurrency);
    }
    if (cell.DATA_TYPE === 'bit') {
      if (displayText === null) return '';
      const textStr = `${displayText}`;
      return textStr ? textStr.charAt(0).toUpperCase() + textStr.slice(1) : '';
    }
    if (cell.DATA_TYPE === 'date') displayText = localDateFormat(displayText);
    if (cell.DATA_TYPE === 'datetime'
      || cell.DATA_TYPE === 'datetime2'
      || ['ReviewDate', 'PostponementTimestamp'].includes(cell.ORIGINAL_NAME)) {
      displayText = timeString(displayText);
    }
    if (cell.ORIGINAL_NAME === 'STATUS_LIGHT') {
      return (
        <>
          {statusIndicator(displayText)}
        </>
      );
    }
    if (cell.ORIGINAL_NAME === 'ROP_DEVIATION') {
      if (!displayText) displayText = 0;
    }
    return (
      <Tooltip title={getToolTipForText(displayText, cell.DATA_TYPE)} placement="bottomLeft">
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{displayText}</div>
      </Tooltip>
    );
  };
  let widthFromView;
  if (userView) {
    widthFromView = userView?.find((x) => x.dataIndex === cell.ORIGINAL_NAME)?.width;
  } else if (isMMCols && !userView) {
    widthFromView = mmDefaultWidths[cell.ORIGINAL_NAME];
  }
  const columnWidth = widthFromView
    ?? ((cell.DISPLAY_NAME?.length ?? cell.ORIGINAL_NAME.length) + 6) * 6;
  cellHead = {
    title: () => columnTitle(cell),
    name: cell.DISPLAY_NAME !== null && cell.DISPLAY_NAME.trim() !== '' ? cell.DISPLAY_NAME : cell.ORIGINAL_NAME,
    name_long: cell.DISPLAY_NAME_LONG ?? cell.DISPLAY_NAME ?? cell.ORIGINAL_NAME,
    dataIndex: cell.ORIGINAL_NAME,
    filterMultiple: true,
    filteredValue: searchFilters[cell.ORIGINAL_NAME]?.[0]?.FilterValue ?? null,
    key: cell.ORIGINAL_NAME,
    width: columnWidth,
    viewOrder: cell.IS_MATERIALMASTER_DEFAULT_VIEW ? cell.MATERIALMASTER_VIEW_ORDER : cell.MATERIALLIST_VIEW_ORDER,
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
        maxWidth: 200,
      },
    }),
    onHeaderCell: () => ({
      columnName: cell.ORIGINAL_NAME,
      updateWidth,
      originalWidth: columnWidth,
    }),
    render,
    ...getFilterDropdown(
      columns, dropdownValues, cell, searchFilters, onChangeFilterSort, mode, defaultPlantsActive, isMMCols, isStaticWL,
    ),
  };

  const missingFilterIcon = () => (<Tooltip title="This column can not be filtered"><MinusOutlined style={{ color: '#bfbfbf' }} /></Tooltip>);

  const getIconColor = () => {
    if (!searchFilters[cell.ORIGINAL_NAME]?.[0]?.FilterValue) {
      return null;
    }
    if (searchFilters[cell.ORIGINAL_NAME].some((x) => x?.FilterOperator !== 'Not')) {
      return '#1890ff';
    }
    return '#C73E1D';
  };

  if (cell.ORIGINAL_NAME === 'md04') {
    cellHead = {
      ...cellHead,
      render: cell.RENDER,
    };
  }
  if (unFilterableCols.includes(cell.ORIGINAL_NAME) || cell.ORIGINAL_NAME === 'md04') {
    cellHead = {
      ...cellHead,
      filterDropdown: <></>,
      filterIcon: missingFilterIcon(),
    };
  } else {
    cellHead = {
      ...cellHead,
      filterIcon: <FilterFilled style={{
        color: getIconColor(),
      }}
      />,
    };
  }
  return cellHead;
};

const generateMDCells = () => {
  const MDCells = [];
  const MDRender = (text, record) => {
    const link = (p, m) => `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*MD04%20RM61R-MATNR=${m};RM61R-WERKS=${p}`;
    const url = link(record.PLANT_FACILITY_SAP_ID, record.MATERIAL_TYPE_SAP_ID.slice(-8));
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ width: '100%', height: '100%' }}
      >
        <a
          target="_blank"
          href={url}
          rel="noreferrer"
        >
          MD04
        </a>
      </div>
    );
  };
  const md04 = {
    DISPLAY_NAME_LONG: 'MD04 URL', DISPLAY_NAME: 'MD04', ORIGINAL_NAME: 'md04', RENDER: MDRender, MATERIALLIST_VIEW_ORDER: null,
  };
  MDCells.push(md04);
  return MDCells;
};

const generateHeaders = (
  columnData,
  dropdownValues,
  columns,
  searchFilters,
  mode = '',
  updateWidth,
  onChangeFilterSort,
  defaultPlantsActive,
  canViewPrices,
  currency,
  isMMCols,
  isStaticWL,
) => {
  let tempHeaderCells = [];
  const tempAllCells = [];
  const filteredCols = columnData.filter((col) => (canViewPrices || (!canViewPrices && !sensitiveValues.find((x) => col.ORIGINAL_NAME === x))));
  filteredCols.forEach((columnHeader) => {
    const headerCell = createColumnHeaderCell(
      filteredCols,
      dropdownValues,
      columnHeader,
      searchFilters,
      mode,
      updateWidth,
      onChangeFilterSort,
      columns,
      defaultPlantsActive,
      canViewPrices,
      currency,
      isMMCols,
      isStaticWL,
    );
    if (isMMCols) {
      if (!columns && columnHeader.IS_MATERIALMASTER_DEFAULT_VIEW && columnHeader.ORIGINAL_NAME !== 'QUICK_SEARCH') {
        tempHeaderCells.push(headerCell);
      }
    } else if (!columns && columnHeader.IS_MATERIALLIST_DEFAULT_VIEW && columnHeader.ORIGINAL_NAME !== 'QUICK_SEARCH') {
      tempHeaderCells.push(headerCell);
    }
    tempAllCells.push(headerCell);
  });
  let flag = true;
  let mdHeaderCell;
  // MD Cells are links to MD in SAP
  const mdCells = generateMDCells();
  mdCells.forEach((mdCell) => {
    mdHeaderCell = createColumnHeaderCell(
      filteredCols,
      dropdownValues,
      mdCell,
      searchFilters,
      mode,
      updateWidth,
      onChangeFilterSort,
      columns,
    );
  });
  columns?.forEach((columnHeader) => {
    const match = filteredCols.find((x) => x.ORIGINAL_NAME === columnHeader.dataIndex);
    if (match || (columnHeader.dataIndex === 'md04' && flag)) {
      let headerCell;
      if (columnHeader.dataIndex === 'md04') {
        flag = false;
        headerCell = { ...mdHeaderCell };
      } else {
        headerCell = createColumnHeaderCell(
          filteredCols,
          dropdownValues,
          match,
          searchFilters,
          mode,
          updateWidth,
          onChangeFilterSort,
          columns,
          defaultPlantsActive,
          canViewPrices,
          currency,
          isMMCols,
          isStaticWL,
        );
      }
      tempHeaderCells.push(headerCell);
    }
  });
  if (!isMMCols) tempAllCells.push(mdHeaderCell);
  if (!columns) {
    tempHeaderCells = tempHeaderCells.sort((a, b) => {
      if (a.viewOrder === null) return 1;
      if (b.viewOrder === null) return -1;
      return a.viewOrder - b.viewOrder;
    });
  }
  return { tempHeaderCells, tempAllCells };
};

const createSearchFilterItem = (columnName, operator, filterValue, multiple = false) => {
  const newSearchFilter = {};
  if (multiple) {
    newSearchFilter[columnName] = filterValue.map((value) => ({
      ColumnName: columnName,
      FilterOperator: operator,
      FilterValue: [value],
    }));
  } else {
    newSearchFilter[columnName] = [
      {
        ColumnName: columnName,
        FilterOperator: operator,
        FilterValue: filterValue,
      },
    ];
  }
  return newSearchFilter;
};

const getMMSelectedItems = (items, wlItems) => {
  if (!items?.length) return wlItems || null;
  if (!wlItems) return items.join('&');
  let mmWlItems = wlItems.split('&');
  mmWlItems = [...mmWlItems, ...items];
  mmWlItems = [...new Set(mmWlItems)];
  return mmWlItems?.join('&') || null;
};

const getConvertedPriceFilters = (matListFilters, defaultCurrency, currencyList) => {
  if (!matListFilters?.searchFilters || !defaultCurrency || defaultCurrency === 'NOK') {
    return matListFilters;
  }
  const newMatListFilters = _.cloneDeep(matListFilters);
  Object.keys(matListFilters.searchFilters)?.forEach(
    (filterCol) => {
      if (currencyValues.includes(filterCol)) {
        const temp = currencyList?.find((x) => defaultCurrency === x?.FROM_CURRENCY);
        matListFilters.searchFilters[filterCol]?.forEach(
          (currFilter, filterIndex) => currFilter?.FilterValue?.forEach(
            (price, i) => {
              if (!price || !temp) return;
              const convertedPrice = (price * (
                temp?.EXCHANGE_RATE / (temp?.FROM_RATIO / temp?.TO_RATIO)
              ))?.toFixed(2).toString();
              newMatListFilters.searchFilters[filterCol][filterIndex]
                .FilterValue[i] = convertedPrice;
            },
          ),
        );
      }
    },
  );
  return newMatListFilters;
};

export {
  generateHeaders,
  createSearchFilterItem,
  modes,
  getMMSelectedItems,
  getConvertedPriceFilters,
  getColDataTypes,
};
