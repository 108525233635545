const widths = {
  ITEM_REQUIRED_SAP_DT: 76.4,
  EDAM_POSTING_DT: 85,
  WORK_ORDER_SAP_ID: 80,
  MATERIAL_BASE_UOM_QTY: 65,
  RESERVATION_SAP_NO: 62.6,
  ITEM_RESERVATION_REVISION_NO: 53.5,
  RESERVATION_ITEM_SAP_NO: 54.1,
  EDAM_MATERIAL_TYPE_ID: 80,
  SUPPLY_PROCESS_TYPE_SAP_ID: 52,
  ORDER_NUMBER: 71,
  WITHDRAWN_QTY: 68,
  NETWORK: 78,
  CC_OPEN_RESERVATION_QTY: 85,
  'Cons Qty 0-84 mth': 85,
  MAINTENANCE_PLANT_FACILITY_SAP_ID: 100,
  FACILITY_SAP_ID: 115,
  KMPME: 100,
  KMPMG: 88,
  UDATETIME: 170,
  EQUNR: 140,
  ITEM_TEXT: 120,
  MATERIAL_MOVEMENT_DOCUMENT_SAP_ID: 90,
  PURCHASE_ORDER_SAP_ID: 90,
  WBSE_DESC: 70,
  EDAM_MATERIAL_DOCUMENT_YR: 50,
  StepWithinRecommendedAction: 70,
  MAINTENANCE_PLANNING_PLANT_FACILITY_SAP_ID: 65,
  STAT: 60,
  CRITICALITY: 65,
  REDUNDANCY: 65,
  MSGRP: 65,
  ORIGINAL_PLANT: 117,
  PURCHASE_ORDER_LINE_SAP_ID: 70,
  PLANT_FACILITY_SAP_ID: 58,
  OLD_VALUE: 100,
  NEW_VALUE: 100,
  CHANGED_BY_EMAIL: 160,
  PLANT_ID: 80,
  MATERIAL_ID: 100,
  CC_MRP_AREA_SAP_ID: 80,
  CHNGIND: 130,
  TCODE: 90,
  CHANGENR: 100,
  USERNAME: 90,
  MEDIUM_FIELD_LABEL: 110,
  VALUE_OLD: 100,
  VALUE_NEW: 100,
  SOURCE_CONS_PLANT_ID: 70,
  GOODS_MOVEMENT_TYPE_SAP_ID: 67,
  WORK_ORDER_TYPE: 70,
  REQUIRED_QTY: 50.5,
  UOM: 59.6,
  MOVEMENT_TYPE: 55,
  'Consumptions 0-84 mth': 110,
  TOTAL_EXCESS_STOCK: 110,
  MATERIAL_MOVEMENT_DOCUMENT_ITEM_SAP_ID: 50,
  MATERIAL_BASE_UOM_CALCULATED_QTY: 40,
  EDAM_LOCAL_CURRENCY_AMT: 80,
  BASE_UNIT_OF_MEASURE_SAP_ID: 50,
  NETWORK_ORDER_SAP_ID: 65,
};

export default widths;
