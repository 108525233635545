import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Drawer, Spin, Dropdown, Menu, Checkbox,
  Tooltip,
} from 'antd';
import {
  DownOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined, SearchOutlined, UpOutlined,
  UndoOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ColumnSelector from './HeaderComponents/ColumnSelector';
import SaveWorklist from './HeaderComponents/SaveWorklist';
import UserViews from './HeaderComponents/UserViews';
import { getConvertedPriceFilters, modes } from './MaterialListFunctions';
import AdvancedSearchContainer from './HeaderComponents/AdvancedSearch/AdvancedSearchContainer';
import allActions from '../../../actions';
import InfoModal from '../../Common/InfoModal';
import { listModes, NUMBER_OF_RECORDS_TO_FETCH } from '../../Common/GlobalConstants';
import { sensitiveValues, unSelectableCols } from '../../Common/SpecialValues';
import ActionMenu from './HeaderComponents/ActionDropdown';
import CreateStaticWLModal from '../Worklists/Static/CreateStaticWLModal';

const MaterialTableHeader = ({
  materialMaster,
  setDisplayHeaderCells,
  worklistID,
  worklistDetails,
  userName,
  updateFilters,
  displayHeaderCells,
  allHeaderCells,
  selectedItems,
  setSelectedItems,
  mode,
  worklistHasView,
  displayData,
  materialViewState,
  setAllowRowClick,
  setMaterialViewState,
  canViewPrices,
  searchFilters,
  materiallistCount,
  updateLocally,
  hasSelectedAll,
  defaultCurrency,
  currencyList,
  getSummaryForCurrentView,
  updateCallback,
}) => {
  const viewRef = useRef();
  const [advSearchDrawer, setAdvSearchDrawer] = useState(false);
  const [colSelector, setColSelector] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [infoModalContent, setInfoModalContent] = useState('');
  const [infoModalTitle, setInfoModalTitle] = useState('');
  const [onlyVisbleCols, setOnlyVisibleCols] = useState(true);
  const [isStatic, setIsStatic] = useState(null);
  const [expandExport, setExpandExport] = useState({
    buttonHover: false,
    menuHover: false,
  });
  const [sWLToBeSaved, setSWLToBeSaved] = useState(null);
  const [createModal, setCreateModal] = useState(false);

  const jobID = useSelector((state) => state.commonState.CSVID);
  const status = useSelector(
    (state) => state.commonState.CSV?.exportStatus,
  );
  const csvStatus = useSelector((state) => state.commonState.CSV);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const userState = useSelector((state) => state.authState.user);

  const [fileType, setExportType] = useState(null);
  const dispatch = useDispatch();
  const statusRef = useRef(null);
  const errorRef = useRef(null);

  const exportTypes = {
    CSV: 'CSV',
    XLSX: 'XLSX',
  };

  useEffect(() => {
    if (mode === modes.STATIC && worklistID) {
      setIsStatic(true);
    } else {
      setIsStatic(false);
    }
  }, [mode, worklistID]);

  const statusString = (currentStatus, currentError) => {
    if (currentError) return 'Failed';
    switch (currentStatus) {
      case 'RUNNING':
        return 'Running';
      case 'COMPLETED':
      case 'TERMINATED':
        return 'Completed';
      default:
        return 'Pending';
    }
  };

  const statusColorMapping = (string, currentError) => {
    if (currentError) return 'red';
    switch (string) {
      case 'RUNNING':
      case 'COMPLETED':
      case 'TERMINATED':
        return 'green';
      case 'FAILED':
        return 'red';
      default:
        return 'orange';
    }
  };

  const getInfoModalContent = (allowed, exportType) => (allowed
    ? (
      <>
        <div style={{ whiteSpace: 'pre-line' }}>
          {`Generating ${exportType ?? ''} file, please do not leave IOTA or close browser.\n`}
        </div>
        <div>
          Current status:
          <span style={{ color: statusColorMapping(statusRef.current, errorRef.current) }}>{` ${statusString(statusRef.current, errorRef.current)}`}</span>
        </div>
        <br />
        {
          exportType === exportTypes.CSV && (
            <div style={{ whiteSpace: 'pre-line' }}>
              {'Note:\n Generated CSV files may have issues with special characters\n'}
              if opened in Excel, rather than importing the CSV data.
            </div>
          )
        }

      </>
    )
    : (
      <>
        {`To generate a ${exportType ?? ''} file, please limit your search by `}
        providing at least one filter in a column header or in Advanced Search
      </>
    ));

  useEffect(() => {
    if (statusRef.current && !status) {
      statusRef.current = 'COMPLETED';
    } else {
      statusRef.current = status;
    }
    setInfoModalContent(getInfoModalContent(true, fileType));
  }, [status]);

  useEffect(() => {
    if (csvStatus?.error) {
      errorRef.current = true;
    } else {
      errorRef.current = null;
    }
    setInfoModalContent(getInfoModalContent(true, fileType));
  }, [csvStatus]);

  useEffect(() => {
    if (!infoModalVisible) statusRef.current = null;
  }, [infoModalVisible]);

  const checkFilters = (filters) => {
    const { MATERIAL_TYPE_DELETION_IND, ...temp } = filters;
    if (_.isEmpty(temp)) {
      return true;
    }
    return false;
  };

  const advSearchCols = ['FACILITY_SAP_ID', 'STORAGE_BIN', 'CONTRACT_NUMBER'];

  // const sortHeaderCells = (hCells) => hCells?.sort((a, b) => {
  //   if (!a?.viewOrder && !b?.viewOrder) return 0;
  //   if (!a?.viewOrder) return 1;
  //   if (!b?.viewOrder) return -1;
  //   return a.viewOrder - b.viewOrder;
  // });

  const filterSensitiveValues = (colsArray) => colsArray.filter(
    (col) => col?.dataIndex
      && !sensitiveValues.includes(col.dataIndex)
      && !advSearchCols.includes(col.dataIndex),
  );

  const getAllColsInOrder = (allCols, dispCols) => {
    let sortedCols = dispCols?.map((x) => x?.dataIndex);
    const tempCols = allCols?.filter((col) => !dispCols?.find(
      (x) => x?.dataIndex && x?.dataIndex === col?.dataIndex,
    ) && !unSelectableCols.includes(col?.dataIndex)).map((x) => x?.dataIndex);
    sortedCols = [...sortedCols, ...tempCols];
    return sortedCols;
  };

  const generateFile = (exportType) => {
    setInfoModalVisible(true);
    setExportType(exportType);
    const listID = materialMaster ? listModes.mmList : listModes.matList;
    if (!isStatic
      && (checkFilters(materialViewState.searchFilters)
        || _.isEmpty(materialViewState.searchFilters))) {
      setInfoModalContent(getInfoModalContent(false, exportType));
      setInfoModalTitle('Please set at least one filter');
    } else {
      const newMatListFilters = getConvertedPriceFilters(
        materialViewState, defaultCurrency, currencyList,
      );
      let orderedCols;
      if (onlyVisbleCols) {
        if (canViewPrices) {
          orderedCols = (displayHeaderCells)?.map((hCell) => hCell?.dataIndex);
        } else {
          orderedCols = filterSensitiveValues(displayHeaderCells || [])
            .map((hCell) => hCell?.dataIndex);
        }
        if (!orderedCols?.length && canViewPrices) {
          orderedCols = [];
        } else if (!canViewPrices && !orderedCols?.length) {
          setInfoModalContent(<>{`Error occurred while generating ${exportType ?? ''}`}</>);
          setInfoModalTitle(
            <>
              <ExclamationCircleOutlined style={{ color: 'red', marginRight: '5px' }} />
              {`Generating ${exportType ?? ''} file`}
            </>,
          );
          return;
        }
        setInfoModalContent(getInfoModalContent(true, exportType));
        setInfoModalTitle(`Generating ${exportType ?? ''} file...`);
        dispatch(allActions.CommonActions.clearCSV());
        dispatch(allActions.CommonActions.setExportListType(listID));
        dispatch(allActions.CommonActions.generateCSV(
          !isStatic ? newMatListFilters?.searchFilters : null,
          orderedCols,
          exportType,
          !isStatic ? 'dynamic' : 'static',
          !isStatic ? null : worklistID,
          listID,
          canViewPrices ? defaultCurrency : null,
        ));
      } else {
        setInfoModalContent(getInfoModalContent(true, exportType));
        setInfoModalTitle(`Generating ${exportType ?? ''} file...`);
        dispatch(allActions.CommonActions.generateCSV(
          newMatListFilters?.searchFilters,
          canViewPrices
            ? getAllColsInOrder(allHeaderCells, displayHeaderCells)
            : getAllColsInOrder(filterSensitiveValues(allHeaderCells),
              filterSensitiveValues(displayHeaderCells)),
          exportType,
          !isStatic ? 'dynamic' : 'static',
          !isStatic ? null : worklistID,
          listID,
          canViewPrices ? defaultCurrency : null,
        ));
      }
    }
  };

  const closeAdvSearch = () => {
    setAdvSearchDrawer(false);
  };

  const toggleAdvSearchDrawer = () => {
    if (colSelector && !advSearchDrawer) setColSelector(false);
    setAdvSearchDrawer((e) => !e);
  };

  const toggleColSelector = (val) => {
    setColSelector(val);
  };

  const closeExport = (hover) => {
    setTimeout(() => {
      setExpandExport((prev) => ({ ...prev, [hover]: false }));
    }, 100);
  };

  const overlay = (
    <Menu
      style={{ width: '250px' }}
      onMouseEnter={
        () => setExpandExport((prev) => ({ ...prev, menuHover: true }))
      }
      onMouseLeave={() => closeExport('menuHover')}
    >
      <Menu.Item key="selectAllCols">
        <Checkbox
          onChange={() => {
            setOnlyVisibleCols(!onlyVisbleCols);
          }}
          checked={onlyVisbleCols}
        >
          Export only visible columns
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="generateCSV" onClick={() => generateFile(exportTypes.CSV)}>
        Export as CSV
      </Menu.Item>
      <Menu.Item key="generateXLSX" onClick={() => generateFile(exportTypes.XLSX)}>
        Export as XLSX
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setAllowRowClick(!(colSelector || advSearchDrawer));
  }, [colSelector, advSearchDrawer]);

  const resetMaterialList = () => {
    if (materialMaster) {
      dispatch(allActions.MMActions.clearMMStoreState());
    } else if (useDefaultSettings) {
      let searchObject = {
        MATERIAL_TYPE_DELETION_IND: [{
          ColumnName: 'MATERIAL_TYPE_DELETION_IND',
          FilterOperator: 'EqualTo',
          FilterValue: ['0'],
        }],
      };
      if (userSettings?.DEFAULT_PLANTS?.length) {
        searchObject = {
          ...searchObject,
          PLANT_FACILITY_SAP_ID: [
            {
              ColumnName: 'PLANT_FACILITY_SAP_ID',
              FilterOperator: 'EqualTo',
              FilterValue: userSettings?.DEFAULT_PLANTS,
            },
          ],
        };
      } else if (userSettings?.DEFAULT_COMPANY_CODES?.length) {
        searchObject = {
          ...searchObject,
          COMPANY_CODE: [
            {
              ColumnName: 'COMPANY_CODE',
              FilterOperator: 'EqualTo',
              FilterValue: userSettings?.DEFAULT_COMPANY_CODES,
            },
          ],
        };
      }
      setMaterialViewState({
        ...materialViewState,
        searchFilters: searchObject,
        sortColumn: undefined,
        sortDirection: undefined,
        scrollPage: {
          currentNoOfRecords: 0,
          noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
        },
      });
    } else {
      dispatch(allActions.MaterialListActions.clearMaterialStoreState(true));
    }
  };

  const getTableTitle = () => {
    if (worklistID) {
      return `Worklist: ${worklistDetails ? worklistDetails.WorklistName : ''}`;
    }
    if (materialMaster) return 'Material Master List';
    return 'Material List';
  };

  const getExportText = () => {
    if (materialMaster) {
      if (csvStatus?.listType === listModes.matList) return 'Exporting Material List';
      return 'Exporting Material Master List';
    }
    if (csvStatus?.listType === listModes.mmList) return 'Exporting Material Master List';
    return 'Exporting Material List';
  };

  const onCreateStaticWorklist = (worklistName, worklistDescription) => {
    const regexStr = /^\s+|\s+$/g; // Remove line breaks and spaces at both ends of the string.
    const tempWLName = worklistName?.replace(regexStr, '');
    const tempWLDescr = worklistDescription?.replace(regexStr, '');
    if (!userState?.uniqueId || !sWLToBeSaved?.WORKLIST_ID) return;
    const metadata = {
      userID: userState.uniqueId,
      worklistID: sWLToBeSaved.WORKLIST_ID,
      worklistName: tempWLName,
      userEmail: userState?.account?.username || null,
      worklistDescription: tempWLDescr,
    };
    dispatch(allActions.WorkListActions.saveSharedSWL(
      metadata, materialMaster ? listModes.mmList : listModes.matList,
    ));
    setCreateModal(false);
  };

  return (
    <div className="material-tab-header">
      <div className="wrap-content">
        <div className="tableTitle">
          {getTableTitle()}
        </div>
        <div className="divDisplayColFilter" ref={viewRef}>
          <ActionMenu
            materiallistCount={materiallistCount}
            materialViewState={materialViewState}
            hasSelectedAll={hasSelectedAll}
            updateCallback={() => updateCallback()}
          />
          {mode !== modes.STATIC
              && (
                <>
                  {
                    !materialMaster ? (
                      <Tooltip title="Summarize columns">
                        <div className="iconButton" onClick={getSummaryForCurrentView}>
                          <CalculatorOutlined />
                        </div>
                      </Tooltip>
                    ) : null
                  }
                  <Tooltip title="Reset to defaults">
                    <div className="iconButton" onClick={resetMaterialList}>
                      <UndoOutlined />
                    </div>
                  </Tooltip>
                  <Button
                    type="primary"
                    className="matlist-button"
                    onClick={() => toggleAdvSearchDrawer()}
                  >
                    <SearchOutlined />
                    Advanced Search
                  </Button>
                </>
              )}
          {userName ? (
            <>
              <SaveWorklist
                materialMaster={materialMaster}
                worklistID={worklistID}
                worklistDetails={worklistDetails}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                displayHeaderCells={displayHeaderCells}
                mode={mode}
                materiallistCount={materiallistCount}
                visibleRecords={materialViewState?.scrollPage}
                materialViewState={materialViewState}
                hasSelectedAll={hasSelectedAll}
                setSWLToBeSaved={setSWLToBeSaved}
                setSWLCreateVisible={setCreateModal}
              />
              <CreateStaticWLModal
                createModal={createModal}
                setCreateModal={setCreateModal}
                onCreateWorklist={onCreateStaticWorklist}
              />
            </>
          ) : null}
          <div className="columnsAndViews">
            <ColumnSelector
              setDisplayHeaderCells={setDisplayHeaderCells}
              displayHeaderCells={displayHeaderCells}
              allHeaderCells={allHeaderCells}
              colSelVisible={colSelector}
              setColSelVisible={toggleColSelector}
              canViewPrices={canViewPrices}
              materialMaster={materialMaster}
            />
            <UserViews
              displayHeaderCells={displayHeaderCells}
              worklistID={worklistID}
              mode={mode}
              worklistHasView={worklistHasView}
              materialMaster={materialMaster}
              updateLocally={updateLocally}
            />
          </div>
          {((displayData && displayHeaderCells) || (isStatic))
              && (
                <>
                  <InfoModal
                    content={infoModalContent}
                    title={infoModalTitle}
                    visible={infoModalVisible}
                    onCancel={() => setInfoModalVisible(false)}
                  />
                  <Dropdown
                    overlay={overlay}
                    visible={expandExport?.buttonHover || expandExport?.menuHover}
                    disabled={!!jobID}
                  >
                    <Button
                      className="matlist-button"
                      onMouseEnter={
                        () => setExpandExport((prev) => ({ ...prev, buttonHover: true }))
                      }
                      onMouseLeave={() => closeExport('buttonHover')}
                    >
                      {!jobID ? <FileExcelOutlined /> : <Spin style={{ marginRight: '6px' }} />}
                      {!jobID ? 'Export Material list'
                        : getExportText()}
                      {
                        expandExport?.buttonHover || expandExport?.menuHover
                          ? <UpOutlined style={{ fontSize: 12 }} />
                          : <DownOutlined style={{ fontSize: 12 }} />
                      }
                    </Button>
                  </Dropdown>
                </>
              )}
        </div>
      </div>

      <Drawer
        title="Advanced search"
        placement="top"
        height="50%"
        visible={advSearchDrawer}
        closable
        onClose={closeAdvSearch}
      >
        <AdvancedSearchContainer
          materialMaster={materialMaster}
          materialListFilters={searchFilters}
          onClose={closeAdvSearch}
          updateFilters={updateFilters}
          canViewPrices={canViewPrices}
        />
      </Drawer>
    </div>

  );
};
export default MaterialTableHeader;

MaterialTableHeader.defaultProps = {
  worklistID: undefined,
  worklistDetails: undefined,
  userName: undefined,
  selectedItems: [],
  worklistHasView: false,
  displayData: undefined,
  canViewPrices: true,
  materialMaster: false,
  searchFilters: {},
  materiallistCount: [],
  hasSelectedAll: false,
  defaultCurrency: null,
  currencyList: [],
  getSummaryForCurrentView: () => null,
};

MaterialTableHeader.propTypes = {
  setDisplayHeaderCells: PropTypes.func.isRequired,
  worklistID: PropTypes.string,
  worklistDetails: PropTypes.objectOf(PropTypes.any),
  userName: PropTypes.string,
  updateFilters: PropTypes.func.isRequired,
  displayHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  allHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any),
  setSelectedItems: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(modes)).isRequired,
  worklistHasView: PropTypes.bool,
  displayData: PropTypes.arrayOf(PropTypes.any),
  materialViewState: PropTypes.objectOf(PropTypes.any).isRequired,
  setAllowRowClick: PropTypes.func.isRequired,
  setMaterialViewState: PropTypes.func.isRequired,
  canViewPrices: PropTypes.bool,
  materialMaster: PropTypes.bool,
  searchFilters: PropTypes.objectOf(PropTypes.any),
  materiallistCount: PropTypes.arrayOf(PropTypes.any),
  updateLocally: PropTypes.func.isRequired,
  hasSelectedAll: PropTypes.bool,
  defaultCurrency: PropTypes.string,
  currencyList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  getSummaryForCurrentView: PropTypes.func,
};
