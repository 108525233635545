import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Menu, Dropdown, Button, Modal,
} from 'antd';
import { DownOutlined, ExclamationCircleFilled, SaveOutlined } from '@ant-design/icons';
import CreateWorklist from '../../Worklists/Dynamic/CreateWorklist';
import AddToStaticWL from './AddToStaticWL';
import { modes } from '../MaterialListFunctions';
import RemoveFromStaticWL from './RemoveFromStaticWL';
import { listModes, STATIC_WL_MAX_ITEMS } from '../../../Common/GlobalConstants';

const SaveWorklist = ({
  worklistID, worklistDetails, selectedItems, setSelectedItems, displayHeaderCells,
  materialViewState, mode, materialMaster, materiallistCount, visibleRecords, hasSelectedAll,
  setSWLToBeSaved, setSWLCreateVisible,
}) => {
  const user = useSelector((state) => state.authState.user);
  const [isCreateWorklist, setIsCreateWorklist] = useState(false);
  const [isSaveAsNewWorklist, setIsSaveAsNewWorklist] = useState(false);
  const [staticWLModal, setStaticWLModal] = useState(false);
  const [createNewSWL, setCreateNewSWL] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [removeFromSWL, setRemoveFromSWL] = useState(false);
  const [allMaterialsSelected, setSelectedAllMaterials] = useState(false);

  const materialWorklists = useSelector((state) => state.worklistState.staticWorklists);
  const mmWorklists = useSelector((state) => state.worklistState.mmStaticWorklists);
  const sharedSWL = useSelector((state) => state.worklistState.sharedStaticWL);

  const showCreateWorklist = () => {
    setIsCreateWorklist(true);
    setIsSaveAsNewWorklist(false);
  };
  const closeCreateWorklist = () => {
    setIsCreateWorklist(false);
    setIsSaveAsNewWorklist(false);
  };
  const showUpdateCreateWorklist = () => {
    setIsCreateWorklist(true);
    setIsSaveAsNewWorklist(true);
  };

  const setAllMatsSelected = () => {
    const visibleRecordsCount = visibleRecords?.currentNoOfRecords
      + visibleRecords?.noOfRecordsToFetch;
    if (materiallistCount?.[0]?.NoofRecords
      && Number.isInteger(visibleRecordsCount)
      && materiallistCount?.[0]?.NoofRecords > visibleRecordsCount
      && selectedItems?.length === visibleRecordsCount) {
      return true;
    }
    return false;
  };

  const createStaticWL = () => {
    const allMatsSelected = setAllMatsSelected();
    if (allMatsSelected && materiallistCount?.[0]?.NoofRecords > STATIC_WL_MAX_ITEMS) {
      setShowWarning(true);
      return;
    }
    setSelectedAllMaterials(allMatsSelected);
    setAllMatsSelected();
    setCreateNewSWL(true);
    setStaticWLModal(true);
  };
  const addToStaticWL = () => {
    const allMatsSelected = setAllMatsSelected();
    if (allMatsSelected && materiallistCount?.[0]?.NoofRecords > STATIC_WL_MAX_ITEMS) {
      setShowWarning(true);
      return;
    }
    setSelectedAllMaterials(allMatsSelected);
    setCreateNewSWL(false);
    setStaticWLModal(true);
  };

  const closeSWLPopup = () => setStaticWLModal(false);
  const closeRemoveSWL = () => setRemoveFromSWL(false);

  const saveSharedSWL = (wl) => {
    if (!wl?.WORKLIST_ID) return;
    setSWLToBeSaved({ ...wl });
    setSWLCreateVisible(true);
  };

  const canUpdateWL = (wl) => wl.CreatedBy === user.account.name
    || wl.CreatedUserEmail === user.account.username
    || (wl.IsWorklistShared && user?.idTokenClaims?.roles?.includes('SuperUser'));

  const WorkddrMenu = (
    <Menu>
      <Menu.Item key="1" disabled={worklistID && worklistDetails && !canUpdateWL(worklistDetails)} onClick={showUpdateCreateWorklist}>
        Save Worklist
      </Menu.Item>
      <Menu.Item key="2" onClick={showCreateWorklist}>
        Save as New Worklist
      </Menu.Item>
    </Menu>
  );

  const matListSaveMenu = () => (
    <Menu>
      <Menu.Item key="1" onClick={showCreateWorklist}>
        Save as Dynamic Worklist
      </Menu.Item>
      <Menu.Item key="2" onClick={createStaticWL} disabled={!selectedItems || !selectedItems?.length}>
        Save selected as Static Worklist
      </Menu.Item>
      <Menu.Item key="3" onClick={addToStaticWL} disabled={!selectedItems || !selectedItems?.length}>
        Add selected to Static Worklist
      </Menu.Item>
    </Menu>
  );

  const staticWLMenu = (
    <Menu>
      {(!sharedSWL || sharedSWL?.WORKLIST_ID !== worklistID)
        ? (
          <Menu.Item key="1" disabled={!selectedItems || !selectedItems?.length} onClick={() => setRemoveFromSWL(true)}>
            Remove selected from worklist
          </Menu.Item>
        ) : (
          <Menu.Item key="2" disabled={!sharedSWL?.NoOfRecords} onClick={() => saveSharedSWL(sharedSWL)}>
            Save worklist to personal worklists
          </Menu.Item>
        )}
    </Menu>
  );

  const getDropdown = (isMaterialMaster) => {
    switch (mode) {
      case modes.STATIC:
        return staticWLMenu;
      case modes.DYNAMIC:
        return WorkddrMenu;
      default:
        return matListSaveMenu(isMaterialMaster);
    }
  };

  return (
    <>
      <Dropdown className="btnWhiteBG matlist-button" overlay={getDropdown(materialMaster)}>
        <Button className="btnWhiteBG matlist-button">
          <SaveOutlined />
          {'Worklist '}
          <DownOutlined />
        </Button>
      </Dropdown>
      {isCreateWorklist && (
        <CreateWorklist
          isNewWorklist={!isSaveAsNewWorklist}
          allMaterialsSelected={allMaterialsSelected}
          closeCreateWorklist={closeCreateWorklist}
          listType={materialMaster ? listModes.mmList : listModes.matList}
          materialViewStore={materialViewState}
          displayHeaderCells={displayHeaderCells}
        />
      )}
      {staticWLModal
        && (
          <AddToStaticWL
            selectedItems={selectedItems}
            create={createNewSWL}
            closeModal={closeSWLPopup}
            displayHeaderCells={displayHeaderCells}
            materialMaster={materialMaster}
            materialCount={materiallistCount?.[0]?.NoofRecords}
            hasSelectedAll={hasSelectedAll}
            materialViewState={materialViewState}
          />
        )}
      {removeFromSWL && (
        <RemoveFromStaticWL
          closeModal={closeRemoveSWL}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          worklistID={worklistID}
          worklistName={worklistDetails.WorklistName}
          allMaterialsSelected={allMaterialsSelected}
          materialMaster={materialMaster}
          staticWorklists={materialMaster ? mmWorklists : materialWorklists}
        />
      )}
      {showWarning && (
        <Modal
          title="Too many items selected"
          style={{ minWidth: '450px', whiteSpace: 'pre' }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() => setShowWarning(false)}
          onCancel={() => setShowWarning(false)}
          visible
          icon={<ExclamationCircleFilled />}
        >
          {
            `Please limit your search before adding to or creating worklist. \nCurrently selected: ${materiallistCount?.[0]?.NoofRecords || '-'} \nMaximum: ${STATIC_WL_MAX_ITEMS}`
          }
        </Modal>
      )}
    </>
  );
};

export default SaveWorklist;

SaveWorklist.defaultProps = {
  worklistID: null,
  worklistDetails: null,
  selectedItems: [],
  displayHeaderCells: [],
  materialMaster: false,
  materiallistCount: [],
  visibleRecords: {},
  hasSelectedAll: false,
};

SaveWorklist.propTypes = {
  worklistID: PropTypes.string,
  worklistDetails: PropTypes.objectOf(PropTypes.any),
  selectedItems: PropTypes.arrayOf(PropTypes.any),
  setSelectedItems: PropTypes.func.isRequired,
  displayHeaderCells: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  mode: PropTypes.oneOf(Object.values(modes)).isRequired,
  materialMaster: PropTypes.bool,
  materiallistCount: PropTypes.arrayOf(PropTypes.any),
  visibleRecords: PropTypes.objectOf(PropTypes.any),
  materialViewState: PropTypes.objectOf(PropTypes.any).isRequired,
  hasSelectedAll: PropTypes.bool,
  setSWLToBeSaved: PropTypes.func.isRequired,
  setSWLCreateVisible: PropTypes.func.isRequired,
};
