import _ from 'lodash';
import * as materialTypes from '../actions/MaterialListDomain/MaterialListActionTypes';
import { NUMBER_OF_RECORDS_TO_FETCH } from '../components/Common/GlobalConstants';

export default function materialReducer(state = { searchID: '' }, action) {
  const addAdvSearchCols = [
    {
      ORIGINAL_NAME: 'FACILITY_SAP_ID',
      DISPLAY_NAME: 'Functional Location',
      DISPLAY_NAME_LONG: 'Functional Location',
      IS_MATERIALLIST_MULTISELECT: null,
      IS_MATERIALLIST_DEFAULT_VIEW: null,
      MATERIALLIST_VIEW_ORDER: null,
      DATA_TYPE: 'nvarchar',
    },
    {
      ORIGINAL_NAME: 'STORAGE_BIN',
      DISPLAY_NAME: 'Storage Bin',
      DISPLAY_NAME_LONG: 'Storage Bin',
      IS_MATERIALLIST_MULTISELECT: null,
      IS_MATERIALLIST_DEFAULT_VIEW: null,
      MATERIALLIST_VIEW_ORDER: null,
      DATA_TYPE: 'nvarchar',
    },
    {
      ORIGINAL_NAME: 'CONTRACT_NUMBER',
      DISPLAY_NAME: 'Contract number',
      DISPLAY_NAME_LONG: 'Contract number',
      IS_MATERIALLIST_MULTISELECT: null,
      IS_MATERIALLIST_DEFAULT_VIEW: null,
      MATERIALLIST_VIEW_ORDER: null,
      DATA_TYPE: 'nvarchar',
    },
  ];

  switch (action.type) {
    case materialTypes.receiveMateriallist: {
      const { searchID, data } = action.payload;
      if (searchID !== state.searchID) return state;
      if (data?.records?.length >= 0) {
        return {
          ...state,
          isLoadingData: false,
          materialListData: data?.records,
          materiallistSearchCount: [{ NoofRecords: data?.NoofRecords ?? 0 }],
          atEndofResults:
            data?.records?.length < state.localMaterialViewStore.scrollPage.noOfRecordsToFetch,
        };
      }
      return {
        ...state,
        isLoadingData: false,
        materialListData: data,
        atEndofResults:
          data?.length < state.localMaterialViewStore.scrollPage.noOfRecordsToFetch,
      };
    }

    case materialTypes.receiveMateriallistAggr: {
      const loadingAggr = {};
      Object.keys(action.payload.data[0])?.forEach((x) => { loadingAggr[x] = false; });
      return {
        ...state,
        loadingAggr: {
          ...state.loadingAggr,
          ...loadingAggr,
        },
        materialListAggr: state.materialListAggr ? {
          ...state.materialListAggr,
          ...action.payload.data[0],
        } : { ...action.payload.data[0] },
      };
    }

    case materialTypes.clearAllAggrValues:
      return {
        ...state,
        materialListAggr: null,
        loadingAggr: {},
      };

    case materialTypes.receiveMateriallistDownload:
      return {
        ...state,
        isLoadingData: false,
        materialListDataDownload: action.payload,
      };

    case materialTypes.clearMaterialList:
      return {
        ...state,
        materialListData: [],
      };

    case materialTypes.clearMaterialStoreState:
      return {
        ...state,
        localMaterialViewStore: {
          ...state.localMaterialViewStore,
          headerCells: null,
          searchFilters: {},
          sortColumn: undefined,
          sortDirection: undefined,
          scrollPage: {
            currentNoOfRecords: 0,
            noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
          },
        },
        displayHeaderCells: null,
        reset: true,
        scrollToRec: { ...state.scrollToRec, yAxis: 0 },
      };

    case materialTypes.setAggrLoading:
    {
      const loadingObj = {};
      action.payload?.forEach((x) => {
        loadingObj[x] = true;
      });
      const loadingAggr = state.loadingAggr
        ? { ...state.loadingAggr, ...loadingObj } : { ...loadingObj };
      return {
        ...state,
        loadingAggr,
      };
    }

    case materialTypes.resetComplete:
      return {
        ...state,
        reset: false,
      };

    case materialTypes.receiveMaterialListColumns:
      return {
        ...state,
        materialListColumns: [...action.payload, ...addAdvSearchCols],
        loaded: { ...state.loaded, materialListColumns: true },
      };

    case materialTypes.receiveMaterialListCurrencyList:
      return {
        ...state,
        COMPANY_CURRENCY_SAP_CD: action.payload,
      };

    case materialTypes.setSelectedRowKeys:
      return {
        ...state,
        selectedRowKeys: action.payload,
      };

    case materialTypes.setMaterialListCount:
      if (action.payload.searchID !== state.searchID) return state;
      return {
        ...state,
        materiallistSearchCount: action.payload.data,
      };

    case materialTypes.checkMatListCount:
      return {
        ...state,
        isLoadingData: false,
        checkMatListCount: !!action.payload,
      };

    case materialTypes.clearMaterialListCount:
      return {
        ...state,
        materiallistSearchCount: null,
      };

    case materialTypes.setMaterialViewLocalFilter:
      return {
        ...state,
        localState: {
          ...state.localState,
          localMaterialSearchFilter: action.payload,
        },

      };
    case materialTypes.setMaterialViewLocalSort:
      return {
        ...state,
        localState: {
          ...state.localState,
          localMaterialSortDetails: action.payload,
        },
      };

    case materialTypes.setLocalMaterialView:
      if (state.reset) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        localMaterialViewStore: action.payload,
      };

    case materialTypes.setDisplayHeaderCells:
      return {
        ...state,
        displayHeaderCells: action.payload,
      };

    case materialTypes.setDisablePlant:
      return {
        ...state,
        disablePlant: action.payload,
      };
    case materialTypes.setLoading:
      return {
        ...state,
        isLoadingData: true,
      };

    case materialTypes.endLoading:
      return {
        ...state,
        isLoadingData: false,
      };

    case materialTypes.storeUserViews:
      return {
        ...state,
        userViews: action.payload.map(
          (view) => ({ ...view, columns: JSON.parse(view.COLUMN_SETUP) }),
        ),
        loaded: { ...state.loaded, userViews: true },
      };

    case materialTypes.selectView:
      return {
        ...state,
        selectedView: action.payload,
      };

    case materialTypes.getDropdownValues:
      return {
        ...state,
        dropdownValues: _.groupBy(action.payload, 'COLUMN_NAME'),
        loaded: { ...state.loaded, dropdownValues: true },
      };

    case materialTypes.setSearchID:
      return {
        ...state,
        searchID: action.payload,
      };

    case materialTypes.scrollToMatVals:
      return {
        ...state,
        scrollToRec: { ...action.payload },
      };

    case materialTypes.setPrevPath:
      return {
        ...state,
        prevPath: action.payload,
      };

    default:
      return state;
  }
}
