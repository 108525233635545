import * as actions from '../actions/WorkListDomain/WorkListActionTypes';

export default function worklistReducer(state = {}, action) {
  let tempWL = state.allWorklistItems;
  const getUpdatedWLItems = (recCountList) => {
    if (!recCountList?.length) return tempWL;
    let nextCount;
    const allWL = tempWL.map((wl) => ({ ...wl }));
    for (let j = 0; j < recCountList.length;) {
      if (!recCountList[j]) return tempWL;
      nextCount = null;
      if (recCountList[j].NoofRecords || recCountList[j].NoofRecords === 0) {
        nextCount = recCountList[j].NoofRecords;
      }
      if (nextCount && recCountList[j + 1] && recCountList[j + 1].ID) {
        const index = tempWL?.findIndex((wl) => wl.WorklistID === recCountList[j + 1].ID);
        if (index !== -1 && (index === 0 || index)) {
          allWL[index].NoOfRecords = nextCount;
        }
      }
      let found = false;
      j += 1;
      while (!found && j < recCountList.length) {
        if (!recCountList[j]) return tempWL;
        if (recCountList[j].NoofRecords) {
          found = true;
        } else {
          j += 1;
        }
      }
    }
    return allWL;
  };

  const getAllWorklistItems = (data, wlDeleted) => {
    if (!data?.length && !wlDeleted) {
      if (!tempWL?.length) return [];
      return [...tempWL];
    }
    if (!tempWL?.length) return [...data];
    const filterWL = (arr, wl) => arr?.some(
      (x) => x?.WorklistID && wl?.WorklistID === x?.WorklistID,
    );
    if (!wlDeleted) {
      const newWLs = data?.filter(
        (newWL) => !filterWL(tempWL, newWL),
      );
      return [...tempWL, ...newWLs];
    }
    const newWLs = tempWL?.filter((oldWL) => filterWL(data, oldWL));
    return [...newWLs];
  };

  switch (action.type) {
    case actions.createWorklistStore:
      return {
        ...state,
        isLoadingData: false,
      };
    case actions.updateWorklistStore:
      return {
        ...state,
        isLoadingData: false,
      };
    case actions.nullRowCount:
      return {
        ...state,
        allWorklistItems: state?.allWorklistItems?.map((item) => {
          if (item?.WorklistID === action?.payload?.worklistID) {
            return {
              ...item,
              NoOfRecords: null,
              SearchFilters: action?.payload?.searchFilters,
            };
          }
          return item;
        }),
      };
    case actions.nullRowCountAll:
      return {
        ...state,
        allWorklistItems: state?.allWorklistItems?.map((item) => ({
          ...item,
          NoOfRecords: null,
        })),
      };
    case actions.receiveWorklists:
      return {
        ...state,
        isLoadingData: false,
        allWorklistItems: getAllWorklistItems(action.payload?.data, action.payload?.wlDeleted),
      };
    case actions.receiveWorklistsCount:
      return {
        ...state,
        isLoadingData: false,
        worklistCount: action.payload,
      };

    case actions.receiveWorklistDetails:
      return {
        ...state,
        isLoadingData: false,
        worklistDetails: action.payload,
      };
    case actions.storeStaticWLs:
      return {
        ...state,
        staticWorklists: action.payload,
      };
    case actions.storeSharedSWL:
      return {
        ...state,
        sharedStaticWL: action.payload?.[0],
      };
    case actions.storeRelevantStaticWLs:
      return {
        ...state,
        relevantStaticWorklists: action.payload,
      };
    case actions.storeStaticWL:
      return {
        ...state,
        staticWorklist: action.payload,
      };
    case actions.addToStaticWLStore:
      return {
        ...state,
        staticWorklist: [...state.staticWorklist, ...action.payload],
      };

    case actions.storeMMStaticWLs:
      return {
        ...state,
        mmStaticWorklists: action.payload,
      };
    case actions.storeMMStaticWL:
      return {
        ...state,
        mmStaticWorklist: action.payload,
      };
    case actions.addToMMStaticWLStore:
      return {
        ...state,
        mmStaticWorklist: [...state.mmStaticWorklist, ...action.payload],
      };

    case actions.setDynamicWLCount:
      tempWL = tempWL?.map((x) => {
        if (x.WorklistID === action.payload?.wlID) {
          return { ...x, NoOfRecords: action.payload?.data?.[0].NoofRecords };
        }
        return { ...x };
      });
      return {
        ...state,
        allWorklistItems: tempWL,
      };
    case actions.setAllDynamicWLCount:
      if (!action.payload?.length) return state;
      return {
        ...state,
        allWorklistItems: getUpdatedWLItems(action.payload),
      };
    default:
      return state;
  }
}
