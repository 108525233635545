/* eslint-disable prefer-template */
import React, { useState, useEffect, useRef } from 'react';
import {
  Typography, Row, Col, Switch, Tooltip, Button,
} from 'antd';
import {
  CheckOutlined, CloseOutlined, FormOutlined,
  QuestionOutlined,
  ToolOutlined,
  FilterOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import allActions from '../../actions';
import './AppBar.css';
import UserMenuContainer from './UserMenuContainer';
import ImpactForm from './ImpactForm';
import styles from './styles.module.scss';
import hasAuthenticatedRole from '../Common/AuthFunctions';

const { Title } = Typography;

export default function AppBar({ isProd }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const user = useSelector((state) => state.authState.user);
  const plants = useSelector((state) => state.commonState.plants);
  const companyCodes = useSelector((state) => state.commonState.companyCodes);
  const [defaultsDisabled, setDefaultsDisabled] = useState(true);
  const [hasConfigAccess, setHasConfigAccess] = useState(false);
  const [hasViewerAccess, setHasViewerAccess] = useState(false);
  const location = useLocation().pathname;
  const [impactFormVisible, setImpactFormVisible] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (user?.idTokenClaims?.roles) {
      const userHasAuthenticatedRole = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_CONFIG_ACCESS,
      );
      setHasConfigAccess(userHasAuthenticatedRole);
      const userHasViewerAccess = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_VIEW_PRICES,
      );
      setHasViewerAccess(userHasViewerAccess);
    }
  }, [user?.idTokenClaims, user?.idTokenClaims?.roles]);

  useEffect(() => {
    if (userSettings?.DEFAULT_PLANTS?.length > 0
      || userSettings?.DEFAULT_COMPANY_CODES?.length > 0) {
      setDefaultsDisabled(false);
    } else {
      setDefaultsDisabled(true);
    }
  }, [userSettings]);

  const onUseDefaultToggle = (checked) => {
    dispatch(allActions.CommonActions.setUseDefaultSettings(checked));
    dispatch(allActions.WorkListActions.nullRowCountAll());
  };

  const submitImpactForm = ({
    plant, material, impact, impactType, notes, overstock = 0,
  }) => {
    setImpactFormVisible(false);
    dispatch(allActions.CommonActions.submitImpactForm(
      plant,
      material,
      impact,
      impactType,
      notes,
      user?.account?.username,
      overstock,
    ));
  };

  const getTitle = () => {
    let title;
    if (userSettings?.DEFAULT_PLANTS?.length > 0 && plants) {
      const plantNames = {};
      plants.forEach((p) => {
        plantNames[p.PLANT_FACILITY_SAP_ID] = p.PLANT_FACILITY_SAP_NM;
      });
      title = userSettings?.DEFAULT_PLANTS.map((p) => p + ' (' + plantNames[p] + ')').join('\r\n');
    } else if (userSettings?.DEFAULT_COMPANY_CODES?.length > 0 && companyCodes.length) {
      const ccNames = {};
      companyCodes.forEach((cc) => {
        ccNames[cc.Company_Code] = cc.Company_Name;
      });
      title = userSettings?.DEFAULT_COMPANY_CODES.map((cc) => cc + ' (' + ccNames[cc] + ')').join('\r\n');
    } else {
      title = 'No plants or company codes are set as default.';
    }
    return <span style={{ whiteSpace: 'pre-line' }}>{title}</span>;
  };

  const onClick = () => {
    dispatch(allActions.AuthActions.setToLimitedViewer());
  };

  return (
    <div className={styles.header} style={{ backgroundColor: isProd ? 'var(--prod-color)' : 'var(--non-prod-color)' }}>
      {!isProd && <div className={styles.limitedViewer}><Button type="primary" onClick={onClick}>limited viewer</Button></div>}
      <Title strong style={{ color: isProd ? 'var(--prod-title)' : 'var(--non-prod-title)' }} className="mainHeader">
        IOTA
      </Title>
      <div className={styles.rightHeader}>
        <div className={styles.rightMenuItems}>
          <Tooltip
            title={getTitle}
            placement="bottom"
          >
            <div className={styles.headerMenuItem} style={{ width: 'fit-content', padding: '0rem 2rem' }} onClick={() => onUseDefaultToggle(!useDefaultSettings)}>
              <Switch
                checkedChildren={<CheckOutlined className={styles.toggleIcon} />}
                unCheckedChildren={<CloseOutlined className={styles.toggleIcon} />}
                checked={defaultsDisabled ? false : useDefaultSettings}
                disabled={defaultsDisabled}
              />
              <span className={styles.linkText}>Default plants/CC</span>
            </div>
          </Tooltip>
          <div className={styles.divider} />
          <Tooltip title="Register impact">
            <div className={styles.headerMenuItem} onClick={() => setImpactFormVisible(true)}>
              <FormOutlined />
              <span className={styles.linkText}>Register</span>
            </div>
          </Tooltip>
          <div className={styles.divider} />
          <div
            className={styles.headerMenuItem}
            style={{ backgroundColor: open ? '#00000065' : '' }}
            onClick={() => {
              setOpen(!open);
            }}
            ref={ref}
          >
            <SettingOutlined />
            <span className={styles.linkText}>Settings</span>
            <div className={`${styles.dropdown} ${open ? styles.openDropdown : styles.closedDropdown}`} style={{ backgroundColor: isProd ? 'var(--prod-color)' : 'var(--non-prod-color)' }}>
              <Tooltip placement="left" title="Personal Settings">
                <NavLink to="/settings" key="/settings" className={`${styles.menuItem} ${location === '/settings' ? styles.active : ''}`}>
                  <UserOutlined />
                  <span className={styles.linkText}>Personal</span>
                  <div className={styles.indicator} />
                  <div className={styles.bottomDivider} />
                </NavLink>
              </Tooltip>
              {hasConfigAccess && (
              <Tooltip placement="right" title="Admin Settings">
                <NavLink to="/config" key="/config" className={`${styles.menuItem} ${location === '/config' ? styles.active : ''}`}>
                  <ToolOutlined />
                  <span className={styles.linkText}>Admin</span>
                  <div className={styles.indicator} />
                  <div className={styles.bottomDivider} />
                </NavLink>
              </Tooltip>
              )}
              {hasViewerAccess
          && (
            <Tooltip placement="right" title="Configuration Settings">
              <NavLink to="/config-view" key="/config-view" className={`${styles.menuItem} ${location === '/config-view' ? styles.active : ''}`}>
                <FilterOutlined />
                <span className={styles.linkText}>Config</span>
                <div className={styles.indicator} />
                <div className={styles.bottomDivider} />
              </NavLink>
            </Tooltip>
          )}
              <Tooltip placement="right" title="IOTA Help center">
                <a target="_blank" href="https://statoilsrm.sharepoint.com/sites/IOTAHelpcenter" rel="noreferrer" className={styles.menuItem}>
                  <QuestionOutlined />
                  <span className={styles.linkText}>Help</span>
                  <div className={styles.indicator} />
                </a>
              </Tooltip>
              {/* hasConfigAccess && (
          <Menu.Item key="/test-center" icon={<ReconciliationOutlined />} className={`${styles.menuItem} ${location === '' ? styles.active : ''}`}>
            <NavLink to="/test-center">Test Center</NavLink>
          </Menu.Item>
        ) */}
            </div>
          </div>
        </div>
        <UserMenuContainer />
        {
        impactFormVisible
        && (
          <ImpactForm
            visible={impactFormVisible}
            onOk={submitImpactForm}
            onCancel={() => setImpactFormVisible(false)}
          />
        )
      }
      </div>

    </div>
  );
}
