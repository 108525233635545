/* Note: All indexes for ExcelJS starts from 1 as rows starts from number in Excel */
import React, { useEffect, useRef, useState } from 'react';
import {
  PageHeader, Card, Col, Row, Divider,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import InfoToolTip from '../../../Common/InfoToolTip';
import NewStockResultTable from './NewStockResultTable';
import NewStockForm from './NewStockForm';
import { nullResults } from './FormData';
import allActions from '../../../../actions';
import ButtonRow from './ButtonRow';
import InfoModal from '../../../Common/InfoModal';
import './NewStockForm.css';

const NewStockAddition = () => {
  const [rows, setRows] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [currentKeyNo, setKeyNo] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [changedRows, setChangedRows] = useState([]);
  const [checkResWithStore, setCheckResWithStore] = useState(false);
  const [currPage, goToPage] = useState(1);
  const [rowRemoved, setRowRemoved] = useState(null);
  const [errorVisible, setErrorVisible] = useState(false);

  const dispatch = useDispatch();

  const storeFormRowsRef = useRef([]);

  const user = useSelector((state) => state.authState?.user);

  useEffect(() => {
    if (user?.uniqueId) {
      dispatch(allActions.SimulationActions.getAllNewStockForms(user.uniqueId));
    }
  }, [user]);

  const newStockDataSource = useSelector(
    (state) => state.simState?.newStockData?.simResults?.notebook_output?.result,
  );
  const storedFormRows = useSelector((state) => state.simState?.newStockData?.formRows);
  const runID = useSelector((state) => state.simState?.newStockData?.jobData?.jobId?.run_id);
  const storedForm = useSelector((state) => state.simState?.newStockData?.jobData?.newStockform);
  const newStockSimError = useSelector((state) => state.simState?.newStockData?.jobData?.error);

  const formatNewStockResults = (stringResults) => {
    let tempData = stringResults.split('[')[1];
    tempData = tempData?.split(']')[0]?.replaceAll('\'', '"');
    const stockRows = tempData?.split('},');
    return stockRows?.map((row, i) => {
      let temp;
      temp = row;
      if (i !== stockRows.length - 1) temp = row.concat('}');
      temp = temp.replaceAll('nan', '"NAN"');
      const newObj = JSON.parse(temp);
      newObj.key = 'newStockResults'.concat(i);
      return newObj;
    });
  };

  useEffect(() => {
    if (newStockDataSource) {
      const stockRows = formatNewStockResults(newStockDataSource);
      if (stockRows?.length) {
        setDataSource([...stockRows]);
      } else setDataSource([]);
    } else if (dataSource?.length || !newStockDataSource) {
      setDataSource([]);
    }
  }, [newStockDataSource]);

  useEffect(() => {
    if (runID && (!dataSource || dataSource.length === 0)) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      setIntervalId(setInterval(() => {
        dispatch(allActions.SimulationActions.getNewStockResults(runID));
      }, 5000));
    } else if ((runID && dataSource) || !runID) {
      clearInterval(intervalId);
    }
  }, [dataSource, runID]);

  useEffect(() => {
    if (checkResWithStore && storedForm && rows) {
      const temp = JSON.parse(storedForm);
      if (!(temp && typeof temp === 'object' && temp.length && rows.length && dataSource?.length)) return;
      const revertedRows = [];
      changedRows.forEach((changedRow) => {
        let tempRow = {};
        if (temp[changedRow]) {
          tempRow = { ...temp[changedRow] };
        } else return;
        let flag = true;
        Object.keys(temp[changedRow])?.forEach((key) => {
          if (flag !== false) {
            flag = null;
          }
          if (tempRow?.[key]?.toString() !== rows[changedRow]?.[key]?.toString()) {
            flag = false;
          }
        });
        if (flag === null) {
          revertedRows.push(changedRow);
        }
      });
      if (revertedRows.length) {
        setChangedRows((prev) => {
          const prevState = [...prev];
          if (!prevState.length) return prev;
          revertedRows.forEach((rowNo) => {
            const index = prevState.indexOf(rowNo);
            if (index !== -1 || index || index === 0) {
              prevState.splice(index, 1);
            }
          });
          return prevState;
        });
      }
      setCheckResWithStore(false);
    }
  }, [checkResWithStore, changedRows]);

  useEffect(() => {
    if (rowRemoved !== null && dataSource?.length > rowRemoved) {
      setDataSource((prevDataSource) => {
        prevDataSource?.splice(rowRemoved, 1);
        if (prevDataSource.length === 0) {
          dispatch(allActions.SimulationActions.clearNewStockResults());
        }
        return [...prevDataSource];
      });
      setRowRemoved(null);
    }
  }, [rowRemoved]);

  useEffect(() => {
    storeFormRowsRef.current = rows;
  }, [rows]);

  useEffect(() => {
    if (newStockSimError) {
      setErrorVisible(true);
    }
  }, [newStockSimError]);

  useEffect(() => {
    if (storedFormRows?.length) {
      setRows(storedFormRows);
    }
    return () => dispatch(allActions.SimulationActions.storeFormRows(storeFormRowsRef));
  }, []);

  const startNewSimulation = () => {
    dispatch(allActions.SimulationActions.clearNewStockResults());
    dispatch(allActions.SimulationActions.storeNewStockSessionForm(rows, uuid()));
    setChangedRows([]);
    setRowRemoved(null);
  };

  const getHighestId = (objArr, str) => {
    let highestId = 0;
    if (!objArr || typeof objArr !== 'object') return null;
    objArr.forEach((x) => {
      const keyNo = Number.parseInt(x?.key?.split(str)[1], 10);
      if (keyNo && keyNo > highestId) {
        highestId = keyNo;
      }
    });
    return highestId;
  };

  const getResultRows = (resultRows, noOfRows) => {
    let allResultRows = [];
    let highestId = 0;
    if (resultRows?.length) {
      allResultRows = resultRows.map((x) => ({ ...x }));
      highestId = getHighestId(allResultRows, 'newStockResults');
      if (highestId === -1) highestId = 0;
    }
    for (let i = resultRows?.length; i < noOfRows; i++) {
      allResultRows.push({ ...nullResults, key: 'newStockResults'.concat(highestId + i) });
    }
    return allResultRows;
  };

  const resetAll = () => {
    setRows([]);
    setChangedRows([]);
    dispatch(allActions.SimulationActions.clearNewStockResults());
    setDataSource([]);
    setFileList([]);
  };

  const cardTitle = (
    <div>
      Forecasted/expected material data
      <InfoToolTip tooltip="Enter expected values (best guess) based on available data to calculate recommended MRP settings for materials" />
    </div>
  );

  const insertReference = () => {
    // code to be implemented
  };

  const closeError = () => {
    setErrorVisible(false);
    dispatch(allActions.SimulationActions.unsetNewStockError());
  };

  return (
    <>
      <InfoModal
        visible={errorVisible}
        title="New Stock Addition error"
        content="Failed to load new stock simulation results"
        onCancel={closeError}
      />
      <PageHeader
        title="New Stock Addition"
        ghost={false}
        style={{ minHeight: '50vh', width: '100%' }}
      >
        <ButtonRow
          fileList={fileList}
          setFileList={setFileList}
          rows={rows}
          setRows={setRows}
          currentKeyNo={currentKeyNo}
          setKeyNo={setKeyNo}
          dataSource={dataSource}
          setDataSource={setDataSource}
          resetAll={resetAll}
          getResultRows={getResultRows}
          getHighestId={getHighestId}
          goToPage={goToPage}
          insertReference={insertReference}
        />
        <Card
          title={cardTitle}
          style={{ width: 'calc(92vw)' }}
        >
          <div className="newStockGrid">
            <NewStockForm
              rows={rows}
              setRows={setRows}
              currentKeyNo={currentKeyNo}
              setKeyNo={setKeyNo}
              isSimRunning={runID && (!dataSource || !dataSource.length)}
              resultRowsCount={dataSource?.length ?? 0}
              setDataSource={setDataSource}
              setChangedRows={setChangedRows}
              setCheckResWithStore={setCheckResWithStore}
              currPage={currPage}
              goToPage={goToPage}
              setRowRemoved={setRowRemoved}
              startNewSimulation={startNewSimulation}
            />
            <div className="results">
              <NewStockResultTable
                dataSource={dataSource} // delete tabData
                pagination={false}
                loading={runID && (!dataSource || !dataSource.length)}
                changedRows={changedRows}
                currPage={currPage}
                visible
              />
            </div>
          </div>
        </Card>
      </PageHeader>
    </>
  );
};

export default NewStockAddition;
