export const createWorklistStore = 'CREATE_WORKLIST';
export const updateWorklistStore = 'UPDATE_WORKLIST';
export const receiveWorklists = 'GET_ALL_WORKLIST';
export const receiveWorklistDetails = 'GET_WORKLIST_DETAILS';
export const nullRowCount = 'SET_DYNAMIC_WL_ROWCOUNT_TO_NULL';
export const nullRowCountAll = 'SET_ALL_DYNAMIC_WL_ROWCOUNT_TO_NULL';
export const deleteWorklistStore = 'DELETE_WORKLIST';
export const receiveWorklistsCount = 'GET_WORKLISTSCOUNT';
export const setDynamicWLCount = 'SET_DYNAMIC_WORKLIST_COUNT';
export const setAllDynamicWLCount = 'SET_ALL_DYNAMIC_WORKLIST_COUNT';
export const createStaticWL = 'CREATE_STATIC_WL';
export const saveSharedStaticWL = 'SAVE_SHARED_STATIC_WL';
export const addToStaticWL = 'ADD_TO_STATIC_WL';
export const addToMMStaticWL = 'ADD_TO_STATIC_WL';
export const storeStaticWLs = 'STORE_STATIC_WLS';
export const storeSharedSWL = 'SET_SHARED_WORKLIST';
export const getSharedSWL = 'GET_SHARED_WORKLIST';
export const storeMMStaticWLs = 'STORE_MM_STATIC_WLS';
export const storeRelevantStaticWLs = 'STORE_RELEVANT_STATIC_WLS';
export const storeStaticWL = 'STORE_STATIC_WL';
export const storeMMStaticWL = 'STORE_MM_STATIC_WL';
export const addToStaticWLStore = 'ADD_TO_STATIC_WL_STORE';
export const addToMMStaticWLStore = 'ADD_TO_MM_STATIC_WL_STORE';
export const allMaterialStaticWL = 'ALL_MATERIAL_STATIC_WL';
export const removeFromStaticWL = 'REMOVE_FROM_STATIC_WL';
export const getStaticWL = 'GET_STATIC_WL';
export const deleteStaticWLs = 'DELETE_STATIC_WLS';
export const setStaticWLView = 'SET_STATIC_WL_VIEW';
