export const receiveMateriallist = 'RECEIVE_MATERIALLIST_DATA_RESULT';
export const receiveMateriallistAggr = 'RECEIVE_MATERIALLIST_AGGREGATES_RESULT';
export const fetchMaterialListCount = 'RECEIVE_MATERIALLIST_COUNT';
export const checkMatListCount = 'CHECK_MATERIAL_LIST_RECORDS_COUNT';
export const receiveMaterialListColumns = 'RECEIVE_MATERIALLIST_COLUMNS_RESULT';
export const receiveMateriallistDownload = 'RECEIVE_MATERIALLIST_DATA_DOWNLOAD';
export const receiveMaterialListCurrencyList = 'RECEIVE_MATERIAL_CURRENCYLIST';
export const setMaterialViewLocalFilter = 'SET_MATERIALVIEW_LOCAL_FILTER';
export const setMaterialViewLocalSort = 'SET_MATERIALVIEW_LOCAL_SORT';
export const setLocalMaterialView = 'SET_LOCAL_MATERIALVIEW';
export const setShareView = 'SHARE_VIEW';
export const setDisplayHeaderCells = 'SET_DISPLAYHEADERCELLS';
export const setLoading = 'SET_LOADING';
export const endLoading = 'END_LOADING';
export const setAggrLoading = 'SET_AGGREGATE_VALUES_LOADING';
export const clearAllAggrValues = 'CLEAR_ALL_COLUMNS_AGGREGATE_VALUES';
export const setMaterialListCount = 'SET_MATERIALLIST_COUNT';
export const clearMaterialListCount = 'CLEAR_MATERIAL_LIST_COUNT';
export const clearMaterialList = 'CLEAR_MATERIAL_LIST';
export const setPrevPath = 'SET_PREVIOUS_PATH';
export const clearMaterialStoreState = 'SET_MATERIALSTATE_NULL';
export const resetComplete = 'RESET_COMPLETE';
export const storeUserViews = 'STORE_USER_VIEWS';
export const deleteUserView = 'DELETE_USER_VIEW';
export const selectView = 'SELECT_VIEW';
export const getMaterialIds = 'GET_MATERIAL_IDS';
export const getDropdownValues = 'GET_DROPDOWN_VALUES';
export const setSearchID = 'SET_SEARCH_ID';
export const scrollToMatVals = 'SET_SCROLL_TO_MATERIAL_VALS';
export const setSelectedRowKeys = 'SET_SELECTED_ROW_KEYS';
export const lastChecked = 'LAST_CHECKED_DATE';
export const setActionComplete = 'SET_ACTION_ON_MATERIAL_COMPLETE';
export const postpone = 'POSTPONE';
export const inspect = 'INSPECT';
export const review = 'REVIEW';
export const setDisablePlant = 'SET_DISABLE_PLANT';
