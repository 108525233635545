import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useVT } from 'virtualizedtableforantd4';
import ReactDragListView from 'react-drag-listview';
import { Table, Tooltip, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ResizableColumnHeader from './ResizableColumnHeader';
import allActions from '../../../actions';
import { currencyValues } from '../../Common/SpecialValues';
import { getConvertedPrice } from '../../Common/DateAndNumberFunctions';

const MaterialList = ({
  additionalTableProps,
  displayHeaderCells,
  setDisplayHeaderCells,
  handleRowClick,
  data,
  loading,
  onChange,
  setAtEndOfList,
  rowSelection,
  materialMaster,
  setCopying,
  defaultCurrency,
  currencyList,
}) => {
  const [currTableTop, setTableTop] = useState(0);
  const [timeStamp, setTimeStamp] = useState(0);
  const dispatch = useDispatch();
  const scrollToRec = useSelector((state) => state.materialState.scrollToRec);
  const materialListAggr = useSelector((state) => state.materialState.materialListAggr);
  const loadingAggr = useSelector((state) => state.materialState.loadingAggr);

  const { Text } = Typography;
  const setMatListTop = () => {
    if (scrollToRec) {
      if (!loading && scrollToRec?.yAxis > 0) {
        setTableTop(scrollToRec.yAxis);
        dispatch(allActions.MaterialListActions.setScrollToMaterial(0));
        return scrollToRec.yAxis;
      }
    }
    return currTableTop;
  };
  const [vt] = useVT(
    () => ({
      onScroll: () => {
        const table = document.querySelector('.materialList .ant-table-body');
        if (table?.scrollHeight - table?.scrollTop <= (table?.clientHeight + 10)) {
          setAtEndOfList(true);
        }
      },
      scroll: { y: window.innerHeight - 320 },
      initTop: setMatListTop(),
    }),
    [scrollToRec, loading],
  );
  const components = {
    header: {
      cell: ResizableColumnHeader,
    },
    ...vt,
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (fromIndex === 0) return; // disallow attempt to move checkbox columns
      let controlledToIndex = toIndex;
      if (toIndex === 0) controlledToIndex = 1; // insert after checkbox column
      const cols = _.clone(displayHeaderCells);
      cols.splice(controlledToIndex - 1, 0, cols.splice(fromIndex - 1, 1)[0]);
      setDisplayHeaderCells(cols);
    },
    nodeSelector: 'th',
  };

  const handleMouseEvent = (e, isPressed) => {
    const currTimeStamp = Date.now();
    if (isPressed) {
      setTimeStamp(currTimeStamp);
      return;
    }
    if ((timeStamp && currTimeStamp - timeStamp > 700)
      || (window.getSelection && window.getSelection()?.type?.toLowerCase() === 'range')
      || (document.getSelection && document.getSelection()?.type?.toLowerCase() === 'range')) {
      setCopying(true);
    } else setCopying(false);
    setTimeStamp(0);
  };

  const getRowKey = (record) => {
    if (materialMaster) return record?.MATERIAL_TYPE_SAP_ID;
    return `${record?.PLANT_FACILITY_SAP_ID}${record?.MATERIAL_TYPE_SAP_ID}`;
  };

  const getSummaryCell = (key, cellIndex, colSpan, text) => (
    <Table.Summary.Cell
      key={key}
      index={cellIndex}
      colSpan={colSpan}
    >
      {
        loadingAggr[key]
          ? (
            <Tooltip title="Loading total value" placement="rightTop">
              <LoadingOutlined style={{ color: 'blue' }} />
            </Tooltip>
          ) : (
            <Tooltip title={text} placement="rightTop">
              <Text>{text}</Text>
            </Tooltip>
          )
      }

    </Table.Summary.Cell>
  );

  const getSummaryText = (currentCurrency) => {
    const formatNumber = (input, currency = false) => {
      const thousandsSeparator = /\B(?=(\d{3})+(?!\d))/g;
      let formatted = (Math.round(input * 100) / 100);
      if (currency) formatted = formatted.toFixed(2);
      formatted = formatted.toString().replace(thousandsSeparator, ' ');
      return formatted;
    };

    const isLoadingAggr = Object.keys(loadingAggr ?? {})?.find((x) => loadingAggr?.[x] ?? false);
    if ((materialListAggr || isLoadingAggr) && displayHeaderCells?.length) {
      const summaryCells = [];
      let cellIndex = 0;
      let colSpan = 1;
      let nextCol = '';
      const curVals = currencyList?.find((x) => currentCurrency === x?.FROM_CURRENCY);
      for (let i = 0; i < displayHeaderCells?.length; i++) {
        const col = displayHeaderCells[i];
        if (materialListAggr?.[col?.dataIndex]
          || materialListAggr?.[col?.dataIndex] === 0 || loadingAggr[col?.dataIndex]) {
          if (cellIndex === 0) {
            summaryCells.push(getSummaryCell('SummaryTotal', 0, 1, 'Sum:'));
            if (colSpan > 1) {
              summaryCells.push(getSummaryCell('Empty', 1, colSpan - 1, ''));
              cellIndex = 2;
            } else cellIndex = 1;
            colSpan = 1;
          } else {
            let val = materialListAggr?.[nextCol];
            const isCurrency = currencyValues.includes(nextCol);
            if (isCurrency
              && currentCurrency && currentCurrency !== 'NOK' && curVals && val) {
              val = getConvertedPrice(
                val, curVals?.FROM_RATIO, curVals?.TO_RATIO, curVals?.EXCHANGE_RATE,
              );
            }
            val = formatNumber(val, isCurrency);
            summaryCells.push(getSummaryCell(nextCol, cellIndex, colSpan, val));
            colSpan = 1;
            cellIndex += 1;
          }
          nextCol = col.dataIndex;
        } else colSpan += 1;
      }
      if (nextCol || summaryCells.length) {
        setTimeout(() => { // Causes re-render that adjusts summary cells position
          const ele = document.querySelector('.ant-table-body');
          if (!ele?.scrollLeft) return;
          if (ele.scrollLeft >= window.innerWidth) ele.scrollLeft -= 1;
          else ele.scrollLeft += 1;
        }, 150);
      }
      if (!nextCol) return summaryCells;

      let val = materialListAggr?.[nextCol];
      const isCurrency = currencyValues.includes(nextCol);
      if (isCurrency
        && currentCurrency && currentCurrency !== 'NOK' && curVals && val) {
        val = getConvertedPrice(
          val, curVals?.FROM_RATIO, curVals?.TO_RATIO, curVals?.EXCHANGE_RATE,
        );
      }
      val = formatNumber(val, isCurrency);
      summaryCells.push(getSummaryCell(nextCol, cellIndex, colSpan, val));
      return summaryCells;
    }
    return [<></>];
  };

  const isSummaryExist = () => {
    if (!materialListAggr && !loadingAggr) return false;
    return !!Object.keys(materialListAggr ?? {})?.some(
      (x) => ((materialListAggr[x] ?? false)
        ? !!displayHeaderCells?.find((cell) => x && cell?.dataIndex === x) : false
      ),
    ) || Object.keys(loadingAggr ?? {})?.some(
      (x) => (loadingAggr?.[x]
        ? !!displayHeaderCells?.find((cell) => x && cell?.dataIndex === x) : false
      ),
    );
  };

  return (
    <div className="test">
      <ReactDragListView.DragColumn
        onDragEnd={dragProps.onDragEnd}
        nodeSelector={dragProps.nodeSelector}
      >
        <Table
          className="materialList"
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
          title={additionalTableProps.title ?? null}
          footer={additionalTableProps.footer ?? null}
          scroll={{
            ...additionalTableProps.scroll,
            y: isSummaryExist()
              ? additionalTableProps.scroll.y - 30 : additionalTableProps.scroll.y,
          }}
          bordered
          pagination={additionalTableProps.pagination}
          components={components}
          columns={displayHeaderCells}
          showSorterTooltip={false} // removes default tooltip "Click to sort..." on column headers
          dataSource={data ?? []}
          rowKey={(record) => getRowKey(record)}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          summary={() => (!materialMaster ? (
            <Table.Summary fixed key="MatlistSummary">
              <Table.Summary.Row key="SummaryRow1" className="MatlistSummary">
                {getSummaryText(defaultCurrency)}
              </Table.Summary.Row>
            </Table.Summary>
          ) : <></>)}
          loading={loading}
          onChange={onChange}
          onRow={(record) => ({
            onClick: (e) => {
              handleRowClick(record.PLANT_FACILITY_SAP_ID, record.MATERIAL_TYPE_SAP_ID, e, record);
            },
            onMouseDown: (e) => handleMouseEvent(e, 1),
            onMouseUp: (e) => handleMouseEvent(e, 0),
          })}
        />
      </ReactDragListView.DragColumn>
    </div>
  );
};

export default MaterialList;

MaterialList.defaultProps = {
  data: [],
  loading: false,
  materialMaster: false,
  defaultCurrency: null,
  currencyList: [],
};

MaterialList.propTypes = {
  additionalTableProps: PropTypes.shape({
    title: PropTypes.func,
    footer: PropTypes.func,
    scroll: PropTypes.objectOf(PropTypes.number),
    pagination: PropTypes.bool,
  }).isRequired,
  displayHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDisplayHeaderCells: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setAtEndOfList: PropTypes.func.isRequired,
  rowSelection: PropTypes.objectOf(PropTypes.any).isRequired,
  setCopying: PropTypes.func.isRequired,
  materialMaster: PropTypes.bool,
  defaultCurrency: PropTypes.string,
  currencyList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
