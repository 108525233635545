import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Modal, Input, Dropdown, Menu, Divider, Tooltip, Row, Col,
  Typography,
  Select,
  Tag,
  Checkbox,
} from 'antd';
import {
  SaveOutlined, StarFilled, DeleteTwoTone, UndoOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import allActions from '../../../../actions';
import {
  showNotification,
} from '../../../../actions/utilities/Notifications/NotificationActions';
import ConfirmModal from '../../../Common/ConfirmModal';
import { modes } from '../MaterialListFunctions';
import { listModes, USE_WORKLIST_VIEW } from '../../../Common/GlobalConstants';
import hasAuthenticatedRole from '../../../Common/AuthFunctions';

const UserViews = ({
  displayHeaderCells, mode, worklistHasView, worklistID, materialMaster, updateLocally,
}) => {
  const userState = useSelector((state) => state.authState.user);
  const userViewsStore = useSelector((state) => state.materialState.userViews);
  const selectedMLView = useSelector((state) => state.materialState.selectedView);
  const selectedMMView = useSelector((state) => state.materialMasterState.selectedView);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const [modalVisible, setModalVisible] = useState(false);
  const [viewName, setViewName] = useState('');
  const [shareData, setShareData] = useState({});
  const [inputValid, setInputValid] = useState(false);
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteViewID, setDeleteViewID] = useState();
  const [newCreatedView, setNewCreatedView] = useState();
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [emailMsg, setEmailMsg] = useState(null);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [publicView, setPublicView] = useState(false);
  const [userViews, setUserViews] = useState([]);

  const dispatch = useDispatch();
  const { Text } = Typography;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (!userViewsStore?.length) return;
    const selectedIds = [selectedMLView, selectedMMView];
    setUserViews(userViewsStore?.sort((a, b) => {
      if (selectedIds.includes(a.VIEW_ID)) return -1;
      if (selectedIds.includes(b.VIEW_ID)) return 1;
      if ((a.PUBLIC_VIEW && b.PUBLIC_VIEW) || (!a.PUBLIC_VIEW && !b.PUBLIC_VIEW)) {
        return a.VIEW_NAME?.toUpperCase() < b.VIEW_NAME?.toUpperCase() ? -1 : 1;
      }
      if (a.PUBLIC_VIEW) return 1;
      return -1;
    }));
  }, [selectedMLView, selectedMMView, userViewsStore]);

  const handleChange = (value) => {
    setInputValid(value.length > 0 && value !== USE_WORKLIST_VIEW
      && !value.trim().toUpperCase().endsWith('_PUBLIC'));
    setViewName(value);
  };

  const cancelOverwrite = () => {
    setConfirmOverwrite(false);
    setInputValid(false);
    setViewName('');
  };

  const checkIfNameExists = () => (userViews?.find((v) => (!publicView && v.VIEW_NAME === viewName)
    || (publicView && v.VIEW_NAME?.endsWith('_PUBLIC') && v.VIEW_NAME?.split('_PUBLIC')?.[0] === viewName)));

  const isSelectedValid = (id) => userViews?.find((x) => x.VIEW_ID === id)?.VIEW_NAME?.length;

  useEffect(() => {
    let view;
    let valid;
    if (materialMaster) {
      valid = isSelectedValid(selectedMMView);
      if (valid) {
        view = userViews?.find((x) => x.VIEW_ID === selectedMMView);
      } else setViewName('');
    } else {
      valid = isSelectedValid(selectedMLView);
      if (valid) {
        view = userViews?.find((x) => x.VIEW_ID === selectedMLView);
      } else setViewName('');
    }
    setInputValid(valid);
    if (view?.VIEW_NAME?.length) {
      if (view.PUBLIC_VIEW) setViewName(view.VIEW_NAME?.split('_PUBLIC')[0]);
      else setViewName(view.VIEW_NAME);
    } else setViewName('');
  }, [selectedMLView, selectedMMView, userViews]);

  const setSelectedView = (view) => {
    if (!view) {
      if ((materialMaster && !selectedMMView) || (!materialMaster && !selectedMLView)) {
        updateLocally();
        return;
      }
    }
    if (materialMaster) {
      dispatch(allActions.MMActions.selectView(view));
    } else {
      dispatch(allActions.MaterialListActions.selectView(view));
    }
  };

  useEffect(() => {
    if (newCreatedView) {
      setSelectedView(newCreatedView);
      setNewCreatedView(null);
    }
  }, [userViews]);

  const saveView = () => {
    if (!inputValid) return;
    let viewID = null;
    if (!confirmOverwrite) {
      const nameExists = checkIfNameExists();
      if (nameExists) {
        setConfirmOverwrite(true);
        return;
      }
      viewID = uuid();
      viewID = viewID.toUpperCase();
    } else viewID = checkIfNameExists()?.VIEW_ID;
    const userID = userState?.uniqueId;
    const userEmail = userState?.account?.username;
    setNewCreatedView(viewID);
    // this is the same for ML and MM
    if (publicView) {
      dispatch(allActions.MaterialListActions
        .setPublicUserView(userID, viewID, `${viewName}_PUBLIC`, displayHeaderCells, userEmail ?? '', materialMaster));
      setModalVisible(false);
    } else {
      dispatch(allActions.MaterialListActions
        .setUserView(userID, viewID, viewName, displayHeaderCells, userEmail ?? '', materialMaster));
      setModalVisible(false);
    }
    setPublicView(false);
    setConfirmOverwrite(false);
  };

  const updateSetting = (settingKey, settingValue) => {
    try {
      const userID = userState.uniqueId;
      dispatch(allActions.CommonActions.setUserSetting(userID, settingKey, settingValue));
    } catch {
      showNotification({
        Type: 'error',
        Title: 'Failed to update user settings',
        Message: 'The system was unable to submit the selected user settings.',
        Placement: 'bottomRight',
      });
    }
  };
  const deleteView = (id, name) => {
    setDeleteModalVisible(false);
    dispatch(allActions.MaterialListActions.deleteUserView(userState?.uniqueId,
      userState.account?.username, id, name, name?.endsWith('_PUBLIC')));
    if (!materialMaster && userSettings?.DEFAULT_USER_VIEW === id) {
      updateSetting('DEFAULT_USER_VIEW', null);
    } else if (materialMaster && userSettings?.DEFAULT_MM_USER_VIEW === id) {
      updateSetting('DEFAULT_MM_USER_VIEW', null);
    }
  };

  const getViewName = (id) => userViews?.find((x) => x.VIEW_ID === id)?.VIEW_NAME;

  const setDeleteView = (id) => {
    setDeleteModalVisible(true);
    setDeleteViewID(id);
  };

  const saveSWLView = (isMaterialMaster) => {
    dispatch(allActions.WorkListActions.setStaticWLView(
      worklistID, userState?.uniqueId,
      displayHeaderCells,
      isMaterialMaster ? listModes.mmList : listModes.matList,
    ));
  };

  const validateEmails = (emails) => {
    let msg = null;
    const invalid = [];
    emails.forEach((email) => {
      const valid = email.match(emailRegex);
      if (!valid) {
        msg = 'Found invalid email';
      } else if (!email.endsWith('equinor.com')) {
        msg = 'Email ID must end with equinor.com';
      } else return;
      invalid.push({
        email,
        msg,
      });
    });
    setInvalidEmails(invalid);
    if (invalid.length) setEmailMsg(msg.replace('mail', 'mail(s)'));
    return invalid;
  };

  const updateEmailList = (e) => {
    const invalid = validateEmails(e);
    let sortedByError = e;
    if (invalid?.length) {
      sortedByError = e?.sort((a, b) => {
        const invalidA = invalid?.find((x) => x.email === a);
        const invalidB = invalid?.find((x) => x.email === b);
        if (invalidB && !invalidA) return 1;
        return -1;
      });
    }
    setEmailList([...sortedByError]);
  };

  const onShareClick = (viewData) => {
    setShareData({ ...viewData });
    setShareModalVisible(true);
  };

  const onPasteCapture = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
    if (splitText?.length) updateEmailList([...emailList, ...splitText]);
  };

  const clearAll = () => {
    setInvalidEmails([]);
    setEmailList([]);
    setEmailMsg(null);
    setShareData({});
    setShareModalVisible(false);
  };

  const shareUserView = () => {
    dispatch(allActions.MaterialListActions.shareUserView(
      userState.uniqueId,
      shareData?.VIEW_ID,
      shareData?.VIEW_NAME,
      shareData?.COLUMN_SETUP,
      userState.account?.username,
      materialMaster,
      emailList,
    ));
    clearAll();
  };

  const unSelectEmail = (e, value) => {
    e.preventDefault();
    updateEmailList(emailList?.filter((x) => x !== value));
  };

  const isSuperUser = () => hasAuthenticatedRole(
    userState?.idTokenClaims?.roles,
    process.env.REACT_APP_SHAREDWORKLISTCREATORROLES,
  );

  const tagRenderer = ({ value }) => {
    const invalid = invalidEmails?.find((x) => x.email === value);
    return (
      <Tooltip title={invalid?.msg || value}>
        <Tag
          key={value}
          closable="true"
          style={{
            border: invalid ? '0.2px solid red' : null,
            backgroundColor: invalid ? 'pink' : null,
            color: invalid ? 'red' : null,
          }}
          onClose={(e) => unSelectEmail(e, value)}
        >
          <span style={{ fontSize: '13px' }}>{value}</span>
        </Tag>
      </Tooltip>
    );
  };

  const viewMenu = (
    <Menu selectedKeys={[materialMaster ? selectedMMView : selectedMLView]} style={{ width: 250 }}>
      {(mode === modes.STATIC || mode === modes.DYNAMIC) && (
        <Menu.Item
          key={USE_WORKLIST_VIEW}
          disabled={!worklistHasView}
          onClick={() => setSelectedView(USE_WORKLIST_VIEW)}
        >
          Worklist view
        </Menu.Item>
      )}
      <Menu.Item
        key="saveCurrentView"
        onClick={() => {
          if (viewName === USE_WORKLIST_VIEW) {
            setViewName('');
            setInputValid(false);
          } else setInputValid(true);
          setModalVisible(true);
        }}
      >
        <SaveOutlined style={{ paddingRight: '3px' }} />
        Save current view
      </Menu.Item>
      <Menu.Item key="resetToDefault" onClick={() => setSelectedView(null)}>
        <UndoOutlined style={{ paddingRight: '3px' }} />
        Reset to system default view
      </Menu.Item>
      {mode === modes.STATIC && (
        <Menu.Item key="saveSWLView" onClick={() => saveSWLView(materialMaster)}>
          <SaveOutlined />
          {worklistHasView ? ' Save Worklist View' : ' Save as Worklist View'}
        </Menu.Item>
      )}
      <Menu.Divider />
      {userViews?.length > 0
        && userViews
          .filter((view) => (materialMaster
            ? view.LIST_MODE === listModes.mmList
            : view.LIST_MODE === listModes.matList))
          .map((view) => (
            <Menu.Item key={view.VIEW_ID}>
              <Row>
                <Col
                  span={12}
                  onClick={() => setSelectedView(view.VIEW_ID)}
                  style={{ color: view.PUBLIC_VIEW ? '#75ba75' : null }}
                >
                  {view.VIEW_NAME}
                </Col>
                <Col span={4} style={{ justifyContent: 'center' }}>
                  {
                    (materialMaster
                      ? userSettings?.DEFAULT_MM_USER_VIEW === view.VIEW_ID
                      : userSettings?.DEFAULT_USER_VIEW === view.VIEW_ID)
                    && (
                      <>
                        <Divider type="vertical" />
                        <Tooltip title="Default View" placement="left">
                          <StarFilled style={{ color: '#1890ff' }} />
                        </Tooltip>
                      </>
                    )
                  }
                </Col>
                {((view.PUBLIC_VIEW && isSuperUser()) || !view.PUBLIC_VIEW)
                && (
                <Col span={4}>
                  <Divider type="vertical" />
                  <Tooltip title="Delete this view" placement="right">
                    <DeleteTwoTone twoToneColor="IndianRed" onClick={() => setDeleteView(view.VIEW_ID, view.VIEW_NAME)} />
                  </Tooltip>
                </Col>
                )}
                {!view.PUBLIC_VIEW
                && (
                <Col span={1}>
                  <Divider type="vertical" />
                  <Tooltip title="Share this view" placement="right">
                    <ShareAltOutlined
                      style={{ color: '#4E89FF' }}
                      onClick={() => onShareClick(view)}
                    />
                  </Tooltip>
                </Col>
                )}
              </Row>
            </Menu.Item>
          ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={viewMenu} placement="bottomRight">
        <Button>Views</Button>
      </Dropdown>
      <Modal
        style={{ minWidth: 400, maxWidth: 600, marginTop: '10%' }}
        title={`Save Material ${materialMaster ? 'Master' : 'List'} View`}
        visible={modalVisible}
        onOK={saveView}
        onCancel={() => setModalVisible(false)}
        footer={(
          <>
            <Button type="primary" onClick={() => saveView()} disabled={!inputValid}>
              Confirm
            </Button>
            <Button onClick={() => (confirmOverwrite
              ? cancelOverwrite()
              : setModalVisible(false))}
            >
              Cancel
            </Button>
          </>
        )}
      >
        <Input
          placeholder="Enter a name for this view"
          maxLength={50}
          value={viewName}
          disabled={confirmOverwrite}
          onChange={(e) => handleChange(e.target.value)}
          onPressEnter={() => saveView()}
          addonAfter={publicView
            ? (
              <Tooltip title="_PUBLIC will be added as suffix to this view name">
                _PUBLIC
              </Tooltip>
            )
            : null}
        />
        {isSuperUser()
        && (
          <>
            <br />
            <br />
            <Checkbox
              checked={publicView}
              onChange={(e) => setPublicView(e?.target?.checked)}
            >
              Create as public views
            </Checkbox>
          </>
        )}
        {confirmOverwrite && !publicView && (
          <div style={{ padding: 6, textAlign: 'center', marginTop: 12 }}>
            You already have a view with that name, do you want to overwrite?
          </div>
        )}
        {confirmOverwrite && publicView && (
        <div style={{ padding: 6, textAlign: 'center', marginTop: 12 }}>
          {`Public view with name ${viewName}_PUBLIC already exist, do you want to overwrite?`}
        </div>
        )}
      </Modal>
      <ConfirmModal
        message={`Delete view named ${getViewName(deleteViewID)}?`}
        title="Delete View"
        visible={deleteModalVisible}
        onOK={() => deleteView(deleteViewID, getViewName(deleteViewID))}
        onCancel={() => setDeleteModalVisible(false)}
      />
      <ConfirmModal
        style={{ minWidth: 550, marginTop: '10%', textAlign: 'center' }}
        title={`Share Material ${materialMaster ? 'Master' : 'List'} View`}
        visible={shareModalVisible}
        onOK={shareUserView}
        onCancel={clearAll}
        okButtonDisabled={invalidEmails?.length > 0 || !emailList?.length}
        message={(
          <>
            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>{shareData?.VIEW_NAME}</Text>
            <br />
            <br />
            <Select
              style={{ width: '400px', border: invalidEmails?.length > 0 ? '1.2px solid red' : null }}
              placeholder={(
                <span style={{ opacity: 0.5 }}>
                  Enter equinor email(s)
                </span>
              )}
              mode="tags"
              value={emailList}
              onChange={updateEmailList}
              allowClear
              onClear={() => updateEmailList([])}
              maxTagCount={5}
              maxTagPlaceholder={(
                <Tooltip title={(
                  <>
                    {emailList.map((x) => (
                      <>
                        {x}
                        <br />
                      </>
                    ))}
                  </>
                )}
                >
                  {`+${emailList.length - 5}`}
                </Tooltip>
              )}
              notFoundContent=""
              onPasteCapture={onPasteCapture}
              tagRender={tagRenderer}
            />
            <br />
            {emailList?.length && invalidEmails?.length
              ? <Text style={{ color: 'red' }}>{emailMsg}</Text> : null}
            <br />
          </>
        )}
      />
    </>
  );
};
export default UserViews;

UserViews.defaultProps = {
  worklistHasView: false,
  worklistID: null,
  materialMaster: false,
};

UserViews.propTypes = {
  displayHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  mode: PropTypes.oneOf(Object.values(modes)).isRequired,
  worklistHasView: PropTypes.bool,
  worklistID: PropTypes.string,
  materialMaster: PropTypes.bool,
  updateLocally: PropTypes.func.isRequired,
};
