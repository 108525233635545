/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Common/Loading';
import { modes } from '../MaterialListView/MaterialListFunctions';
import MaterialMasterContainer from './MaterialMasterContainer';
import allActions from '../../../actions';
import { listModes } from '../../Common/GlobalConstants';

function MMStaticWLContainer() {
  const { worklistID } = useParams();
  const worklists = useSelector((state) => state.worklistState.mmStaticWorklists);
  const sharedSWL = useSelector((state) => state.worklistState.sharedStaticWL);
  const [worklistDetails, setWorklistDetails] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allActions.WorkListActions.setSharedSWL(null));
  }, []);

  useEffect(() => {
    if (sharedSWL && sharedSWL.WORKLIST_ID === worklistID) {
      setWorklistDetails(sharedSWL);
    }
  }, [sharedSWL]);

  useEffect(() => {
    if (worklists?.length > 0 && worklistID) {
      const worklist = worklists.find((e) => e.WORKLIST_ID === worklistID);
      if (worklist) {
        setWorklistDetails(worklist);
      } else if (sharedSWL?.WORKLIST_ID !== worklistID) {
        dispatch(allActions.WorkListActions.getStaticWLByID(worklistID, listModes.mmList));
      }
    }
  }, [worklists, worklistID]);

  return (
    worklistDetails && worklistID
      ? (
        <MaterialMasterContainer
          worklistID={worklistID}
          worklistDetails={{
            WorklistID: worklistDetails?.WORKLIST_ID,
            WorklistName: worklistDetails?.WorklistName,
            WorklistDescription: worklistDetails?.WorklistDescription,
            CreatedDate: worklistDetails?.CreatedDate,
            ModifiedDate: worklistDetails?.ModifiedDate,
            NoOfRecords: worklistDetails?.NoOfRecords,
          }}
          headerCells={worklistDetails?.SelectedColumns ? JSON.parse(worklistDetails.SelectedColumns) : []}
          sortColumn={worklistDetails?.SortColumn}
          sortDirection={worklistDetails?.SortDirection}
          mode={modes.STATIC}
        />
      )
      : <Loading text="Loading Worklist" />
  );
}

export default MMStaticWLContainer;
