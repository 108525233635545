import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CloseOutlined, ExclamationCircleOutlined, InfoCircleTwoTone,
} from '@ant-design/icons';
import {
  Button, Col, Row, Select, Form, Tooltip, DatePicker, Input, Empty, Tag,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import './AdvancedSearch.css';
import AdvancedSearchColumns from './AdvancedSearchColumns';
import {
  getOption, dropdownOptions, getBoolOptions, getTypeOptionArray,
} from './AdvancedSearchOptions';
import { isInteger } from '../../../../Common/DateAndNumberFunctions';
import allActions from '../../../../../actions';

const AdvancedSearchItem = ({
  id, column, condition, input, removeItem, form, canViewPrices, materialMaster,
}) => {
  const [localValues, setLocalValues] = useState({
    column, condition, input,
  });
  const plantMaterialColumns = useSelector((state) => state.materialState.materialListColumns);
  const disablePlant = useSelector((state) => state.materialState.disablePlant);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const defaultPlants = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);
  const defaultCCs = useSelector((state) => state.commonState.userSettings?.DEFAULT_COMPANY_CODES);
  const dropdownValues = useSelector((state) => state.materialState.dropdownValues);
  const materialMasterColumns = useSelector(
    (state) => state.materialMasterState.materialListColumns,
  );
  const [selectedType, setSelectedType] = useState();
  const [isMultiple, setIsMultiple] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [columnList, setColumnList] = useState(plantMaterialColumns);
  const dispatch = useDispatch();

  const updateColumns = (materialCols, mmCols, isMM) => {
    if (materialCols?.length && !isMM) {
      setColumnList(materialCols);
    }
    if (mmCols?.length && isMM) {
      setColumnList(mmCols);
    }
  };

  useEffect(() => {
    updateColumns(plantMaterialColumns, materialMasterColumns, materialMaster);
  }, [plantMaterialColumns, materialMasterColumns, materialMaster]);

  const getDataType = (col) => columnList?.find((x) => x.ORIGINAL_NAME === col)?.DATA_TYPE;

  const isNumberColumn = (val) => ['bigint', 'int', 'decimal', 'float'].includes(val);
  const isIntegerColumn = (val) => ['bigint', 'int'].includes(val);
  const isDropdown = (col) => col in dropdownValues;
  const isBit = (col) => getDataType(col) === 'bit';

  useEffect(() => {
    let disabled;
    if ((defaultCCs?.length > 0 || defaultPlants?.length > 0)
      && (useDefaultSettings)) {
      disabled = true;
    } else {
      disabled = false;
    }
    dispatch(allActions.MaterialListActions.setDisablePlant(disabled));
  }, [defaultCCs, defaultPlants, useDefaultSettings]);

  useEffect(() => {
    const sortedList = dropdownValues ? dropdownValues[localValues?.column]?.sort((a, b) => {
      if (/^\d+$/.test(a.DROPDOWN_VALUE) && /^\d+$/.test(b.DROPDOWN_VALUE)) {
        return a.DROPDOWN_VALUE - b.DROPDOWN_VALUE;
      }
      return a.DROPDOWN_VALUE > b.DROPDOWN_VALUE ? 1 : -1;
    }) : dropdownValues[localValues?.column];
    setDropdownList(sortedList ? [...sortedList] : sortedList);
  }, [dropdownValues, localValues.column]);

  const addValue = (key, value = [], multiple = false, dropdown = false, event) => {
    const valueString = value?.toString() || '';
    const tempValues = { ...localValues };
    let newValues;
    if (multiple) {
      newValues = dropdown ? [...tempValues[key], valueString] : [...value];
      if (isIntegerColumn(selectedType)) {
        const hasInvalidInput = newValues?.some((e) => !(isInteger(e)));
        newValues = hasInvalidInput
          ? newValues.sort((a) => (!isInteger(a) ? -1 : 0))
          : newValues;
      }
    } else {
      newValues = [valueString];
    }
    tempValues[key] = newValues;
    const obj = {};
    obj[`${id}+${key}`] = tempValues[key];
    form.setFieldsValue(obj);
    setLocalValues(tempValues);
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.target.blur();
    }
  };

  const sortOptions = () => {
    if (!dropdownList) {
      return;
    }
    const sortedList = dropdownList?.sort((a, b) => {
      const aSearch = localValues?.input?.find((x) => a.DROPDOWN_VALUE === x);
      const bSearch = localValues?.input.find((x) => b.DROPDOWN_VALUE === x);
      if ((aSearch && bSearch) || (!aSearch && !bSearch)) {
        if (/^\d+$/.test(a.DROPDOWN_VALUE) && /^\d+$/.test(b.DROPDOWN_VALUE)) {
          return a.DROPDOWN_VALUE - b.DROPDOWN_VALUE;
        }
        return a.DROPDOWN_VALUE > b.DROPDOWN_VALUE ? 1 : -1;
      }
      if (aSearch) {
        return -1;
      }
      return 1;
    });
    setDropdownList(sortedList.length ? [...sortedList] : dropdownList);
  };

  const removeValue = (key, value) => {
    const tempValues = { ...localValues };
    tempValues[key] = value ? [...tempValues[key].filter((e) => e !== value)] : [];
    const obj = {};
    obj[`${id}+${key}`] = tempValues[key];
    form.setFieldsValue(obj);
    setLocalValues(tempValues);
  };

  // This is a band-aid solution to an issue with Ant Design Select
  // The fix is currently in Preview, and when it is released, we'll
  // update and remove this hacky setTimeout-solution
  // Currently, onClear calls onRemove once for each value, which
  // doesn't work with our way of updating state
  const clearValue = (key) => {
    setTimeout(() => {
      const tempValues = { ...localValues };
      tempValues[key] = [];
      const obj = {};
      obj[`${id}+${key}`] = [];
      form.setFieldsValue(obj);
      setLocalValues(tempValues);
    }, 100);
  };

  const pasteValue = (key, event) => {
    event.preventDefault();
    const tempValues = { ...localValues };
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').map((item) => item.trim()).filter((s) => s.length > 0);
    if (!splitText || splitText.length < 1) return;
    tempValues[key] = [...new Set([...tempValues[key], ...splitText])];
    if (isNumberColumn(selectedType)) {
      const hasInvalidInput = tempValues[key].some((e) => !(isInteger(e)));
      tempValues[key] = hasInvalidInput
        ? tempValues[key].sort((a) => (!isInteger(a) ? -1 : 0))
        : tempValues[key];
    }
    const obj = {};
    obj[`${id}+${key}`] = tempValues[key];
    form.setFieldsValue(obj);
    setLocalValues(tempValues);
  };

  const updateInputIsMultiple = (selectedCondition, type) => {
    const allowMultiple = !!getTypeOptionArray(type)
      .find((x) => x.value === selectedCondition)?.multiple;
    setIsMultiple(allowMultiple);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const type = getDataType(localValues.column);
        const result = await updateInputIsMultiple(condition, type);
        setIsMultiple(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [condition]);

  const updateConditionSelector = (selectedCondition, type) => {
    const tempValues = { ...localValues };
    tempValues.condition = selectedCondition;
    tempValues.input = [];
    setLocalValues(tempValues);
    updateInputIsMultiple(selectedCondition, type);
    const obj = {};
    obj[`${id}+column`] = localValues.column;
    obj[`${id}+condition`] = selectedCondition;
    obj[`${id}+input`] = type?.toString().includes('date') ? null : [];
    form.setFieldsValue(obj);
  };

  const updateColumnSelector = (value) => {
    // update local values
    const tempValues = { ...localValues };
    tempValues.column = value;
    tempValues.condition = '';
    tempValues.input = [];
    setLocalValues(tempValues);
    // update condition and value selectors
    const type = getDataType(value);
    setSelectedType(type);
    updateInputIsMultiple(tempValues.condition, type);
    // update form object
    const obj = {};
    obj[`${id}+column`] = value;
    obj[`${id}+condition`] = 'EqualTo';
    obj[`${id}+input`] = type?.toString().includes('date') ? null : [];
    form.setFieldsValue(obj);
  };

  const getOptions = (type) => {
    const options = isDropdown(localValues.column)
      ? dropdownOptions
      : getTypeOptionArray(type || getDataType(localValues.column));
    return (
      <Select
        placeholder="Select condition"
        onChange={(e) => updateConditionSelector(e, selectedType)}
        notFoundContent={(
          <Empty
            description="Select a column first"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        {options.map((o) => getOption(o.value, o.display, o.symbol, o.disabled))}
      </Select>
    );
  };

  const getDropdownOptions = (columnName) => (
    <Form.Item
      name={`${id}+input`}
      id={`${id}+input`}
      label="Input"
      rules={[{ required: true }]}
    >
      <Select
        mode={dropdownValues[columnName] ? 'multiple' : null}
        allowClear
        placeholder="Select option"
        onSelect={(e) => addValue('input', e, !!dropdownValues[columnName], true)}
        onDeselect={(e) => removeValue('input', e)}
        onClear={() => clearValue('input')}
        style={{ width: '100%' }}
        onDropdownVisibleChange={(e) => sortOptions(e)}
        onInputKeyDown={(e) => onPressEnter(e)}
        onPasteCapture={(e) => pasteValue('input', e)}
        disabled={columnName === 'PLANT_FACILITY_SAP_ID' && disablePlant}
      >
        {
          dropdownList
            ? dropdownList.map((x) => getOption(x.DROPDOWN_VALUE, x.DROPDOWN_VALUE))
            : getBoolOptions()
        }
      </Select>
    </Form.Item>
  );
  const validator = (value) => {
    if (!value || value?.length === 0) return Promise.reject(new Error('This field is required'));
    if (isIntegerColumn(value) && [...value].some((x) => !isInteger(x))) {
      return Promise.reject(new Error('Not a valid integer'));
    }
    return (isMultiple
      && isNumberColumn(selectedType)
      && value.some((x) => Number.isNaN(Number(x))))
      ? Promise.reject(new Error('Only numbers are valid inputs'))
      : Promise.resolve();
  };

  const tagRender = ({
    label, value, closable,
  }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const onClose = (e) => {
      e.preventDefault();
      removeValue('input', value);
    };
    const valid = !(isNumberColumn(selectedType) && !isInteger(String(value).trim()));
    return (
      <Tooltip title={valid ? '' : 'Only numbers are valid inputs'}>
        <Tag
          key={value}
          color={valid ? '' : 'red'}
          icon={valid ? '' : <ExclamationCircleOutlined />}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ fontSize: '14px' }}
        >
          {label}
        </Tag>
      </Tooltip>
    );
  };

  const getInput = (type, isMultipleVals) => {
    switch (type) {
      case 'date':
      case 'datetime':
      case 'datetime2':
        return (
          <Form.Item
            name={`${id}+input`}
            id={`${id}+input`}
            label="Input"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            name={`${id}+input`}
            id={`${id}+input`}
            label="Input"
            rules={[{ validator: (_, value) => validator(value) }]}
          >
            {
              isMultipleVals
                ? (
                  <Select
                    placeholder="Press enter between values if inputting multiple"
                    maxTagCount={7}
                    maxTagTextLength={40}
                    maxTagPlaceholder={`+ ${localValues?.input?.length - 7} values`}
                    allowClear
                    mode="tags"
                    notFoundContent=""
                    style={{ width: '100%' }}
                    onChange={(e) => addValue('input', e, true)}
                    onDeselect={(e) => removeValue('input', e)}
                    onPasteCapture={(e) => pasteValue('input', e)}
                    onClear={() => clearValue('input')}
                    tagRender={tagRender}
                  />
                )
                : (
                  <Input
                    placeholder="Enter value"
                    allowClear
                    onChange={(e) => addValue('input', e.target.value)}
                  />
                )
            }
          </Form.Item>
        );
    }
  };

  useEffect(() => {
    const type = getDataType(localValues.column);
    updateInputIsMultiple(localValues.condition, type);
    const obj = {};
    obj[`${id}+column`] = localValues.column;
    obj[`${id}+condition`] = localValues.condition;
    if (localValues.input.length > 0) {
      if (Array.isArray(localValues.input)) {
        if (type === 'date' || type === 'datetime2') {
          const newMoment = moment(localValues.input[0], 'YYYY-MM-DD');
          obj[`${id}+input`] = newMoment;
        } else {
          obj[`${id}+input`] = localValues.input;
        }
      }
    }
    form.setFieldsValue(obj);
  }, []);

  useEffect(() => {
    if (column) {
      setSelectedType(getDataType(column));
    }
  }, [column]);

  useEffect(() => {
    const options = isDropdown(localValues.column)
      ? dropdownOptions
      : getTypeOptionArray(selectedType || getDataType(localValues.column));
    if (options?.length) updateInputIsMultiple(options?.[0]?.value, selectedType);
  }, [localValues.column, selectedType]);

  return (
    <Row gutter={8}>
      <Col span={6} style={{ textAlign: 'left' }}>
        <Form.Item
          name={`${id}+column`}
          id={`${id}+column`}
          label="Column"
          rules={[{ required: true }]}
        >
          <AdvancedSearchColumns
            columns={columnList}
            selectedColumn={localValues.column}
            updateValue={(_key, value) => updateColumnSelector(value)}
            canViewPrices={canViewPrices}
            materialMaster={materialMaster}
          />
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item
          name={`${id}+condition`}
          id={`${id}+condition`}
          label="Condition"
          rules={[{ required: true }]}
        >
          {getOptions(selectedType)}
        </Form.Item>
      </Col>
      <Col span={11}>
        {(isDropdown(localValues.column) || isBit(localValues.column))
          ? getDropdownOptions(localValues.column)
          : getInput(selectedType, isMultiple)}
      </Col>
      <Col span={2}>
        <Row>
          <Col span={8}>
            <Tooltip title="Paste or type values to search for, press enter to separate search phrases">
              <InfoCircleTwoTone />
            </Tooltip>
          </Col>
          <Col span={16}>
            <Tooltip title="Remove this search element" mouseEnterDelay={1.5}>
              <Button style={{ width: '100%' }} onClick={() => removeItem(id)}><CloseOutlined /></Button>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AdvancedSearchItem;

AdvancedSearchItem.propTypes = {
  id: PropTypes.string.isRequired,
  column: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string]),
  condition: PropTypes.string,
  input: PropTypes.arrayOf(PropTypes.any),
  removeItem: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
  }).isRequired,
  canViewPrices: PropTypes.bool,
  materialMaster: PropTypes.bool,
};

AdvancedSearchItem.defaultProps = {
  materialMaster: false,
  column: [],
  condition: '',
  input: [],
  canViewPrices: true,
};
