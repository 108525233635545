import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const CreateStaticWLModal = ({
  createModal, setCreateModal, onCreateWorklist,
}) => {
  const materialWorklists = useSelector((state) => state.worklistState.staticWorklists);
  const mmWorklists = useSelector((state) => state.worklistState.mmStaticWorklists);
  const [worklistName, setWorklistName] = useState('');
  const [worklistDescription, setWorklistDescription] = useState();
  const [nameInUse, setNameInUse] = useState(false);

  useEffect(() => {
    if (materialWorklists?.some((e) => e.WorklistName === worklistName)
      || mmWorklists?.some((e) => e.WorklistName === worklistName)) setNameInUse(true);
    else setNameInUse(false);
  }, [worklistName]);

  return (
    <Modal
      title="Create new Static Worklist"
      visible={createModal}
      onOk={() => onCreateWorklist(worklistName, worklistDescription)}
      okButtonProps={{ disabled: !worklistName || worklistName.length < 1 || nameInUse }}
      onCancel={() => setCreateModal(false)}
      style={{ minWidth: '450px' }}
    >
      <TextArea
        autoSize
        placeholder="Worklist Name"
        value={worklistName}
        onChange={(e) => setWorklistName(e.target.value)}
      />
      <br />
      {nameInUse && <span style={{ color: '#cf1322' }}>Name is already in use.</span>}
      <br />
      <TextArea
        autoSize
        placeholder="Worklist Description"
        value={worklistDescription}
        onChange={(e) => setWorklistDescription(e.target.value)}
      />
    </Modal>
  );
};

export default CreateStaticWLModal;

CreateStaticWLModal.defaultProps = {
  createModal: false,
};

CreateStaticWLModal.propTypes = {
  createModal: PropTypes.bool,
  setCreateModal: PropTypes.func.isRequired,
  onCreateWorklist: PropTypes.func.isRequired,
};
