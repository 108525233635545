/* eslint-disable camelcase */

import * as actions from './WorkListActionTypes';
import apiAction from '../utilities/CommonMethods';
import {
  Base_URL,
  Create_Worklist_URL,
  Get_Worklists_URL,
  Get_WorklistDetails_URL,
  Update_Worklist_URL,
  Delete_Worklist_URL,
  StaticWorklist,
  StaticWorklists,
  RelevantStaticWorklists,
  SWLAddItems,
  Get_MaterialList_URL,
  Get_MaterialList_SearchCount_URL,
  Get_All_WorklistsCount_URL,
  Get_MaterialMaster_URL,
  MMSWLUpdateItems,
  SharedStaticWorklist,
  SaveSharedSWL,
} from '../APIUrls';
import { listModes, NUMBER_OF_RECORDS_TO_FETCH } from '../../components/Common/GlobalConstants';
import { getMMSelectedItems } from '../../components/Views/MaterialListView/MaterialListFunctions';

function creationErrorNotification(errorMsg, worklistState) {
  if (errorMsg.message.includes('Violation of UNIQUE KEY constraint')) {
    return {
      Type: 'error',
      Title: 'Worklist creation/update failure',
      Message: `${worklistState} unique key is already in use`,
    };
  }
  if (errorMsg.message.includes('Sorry you are not authorized')) {
    return {
      Type: 'error',
      Title: 'Worklist creation/update failure',
      Message: 'You are not authorized to create a public worklist',
    };
  }
  return {
    Type: 'error',
    Title: 'Worklist creation/update failure',
    Message: 'Something went wrong. Please try again',
  };
}

const createWorklistAction = (
  materialViewStore, displayHeaderCells, worklistState, userState, listType,
) => {
  const url = Base_URL + Create_Worklist_URL;
  function createWorklistfunction(data) {
    return {
      type: actions.createWorklistStore,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: {
      WorklistName: worklistState.worklistName,
      WorklistDescription: worklistState.worklistDescription,
      SortColumn: materialViewStore.sortColumn,
      SortDirection: materialViewStore.sortDirection,
      SearchFilters: JSON.stringify(materialViewStore.searchFilters),
      SelectedColumns: JSON.stringify(displayHeaderCells),
      SearchFiltersTable: materialViewStore.searchFilters,
      CreatedBy: userState?.account?.name ? userState.account.name : 'N/A',
      CreatedUserEmail: userState?.account?.username ? userState.account.username : 'N/A',
      IsWorklistShared: worklistState.isWorklistShared,
      ListType: listType,
    },
    onSuccess: createWorklistfunction,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Worklist created',
      Message: `Worklist named ${worklistState.worklistName} has been created`,
    },
    onFailure: () => console.log('Error occured on creating worklist'),
    onFailureNotification: (errorMsg) => creationErrorNotification(
      errorMsg, worklistState.worklistName,
    ),
    label: actions.createWorklistStore,
  });
};

const fetchWorklistDetails = (worklistID) => {
  const url = Base_URL + Get_WorklistDetails_URL;
  function getWorklistDetails(data) {
    return {
      type: actions.receiveWorklistDetails,
      payload: data,
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: { WorklistID: worklistID },
    onSuccess: getWorklistDetails,
    onFailure: () => console.log('Error occured fetching worklist details'),
    label: actions.receiveWorklistDetails,
  });
};

const nullRowCount = (worklistID, searchFilters) => ({
  type: actions.nullRowCount,
  payload: {
    worklistID,
    searchFilters: JSON.stringify(searchFilters),
  },
});

const nullRowCountAll = () => ({
  type: actions.nullRowCountAll,
});

const setSharedSWL = (swl) => ({
  type: actions.storeSharedSWL,
  payload: swl,
});

const updateWorklistAction = (
  materialViewStore, worklistState, userState, displayHeaderCells, listType,
) => {
  const url = Base_URL + Update_Worklist_URL;
  return apiAction({
    url,
    method: 'POST',
    data: {
      WorklistID: worklistState.worklistId,
      WorklistName: worklistState.worklistName,
      WorklistDescription: worklistState.worklistDescription,
      SortColumn: materialViewStore.sortColumn,
      SortDirection: materialViewStore.sortDirection,
      SearchFilters: JSON.stringify(materialViewStore.searchFilters),
      SelectedColumns: JSON.stringify(displayHeaderCells),
      SearchFiltersTable: materialViewStore.searchFilters,
      ModifiedBy: userState?.account?.name ? userState.account.name : 'N/A',
      ModifiedByUserEmail: userState?.account?.name ? userState.account.username : 'N/A',
      IsWorklistShared: worklistState.isWorklistShared,
      ListType: listType,
    },
    onSuccess: () => fetchWorklistDetails(worklistState.worklistId),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Update Worklist',
      Message: `Worklist named ${worklistState.worklistName} has been updated`,
    },
    onFailure: () => console.log('Error occured on updating worklist'),
    onFailureNotification: (errorMsg) => creationErrorNotification(
      errorMsg, worklistState.worklistName,
    ),
    label: actions.createWorklistStore,
  });
};

const getWorklists = (userEmail, wlDeleted = false) => {
  const url = Base_URL + Get_Worklists_URL;
  function setWorklists(data) {
    return {
      type: actions.receiveWorklists,
      payload: {
        data,
        wlDeleted,
      },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      LoggedInUserEmail: userEmail,
    },
    onSuccess: setWorklists,
    onFailure: () => console.log('Error occured fetching worklist'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Worklists',
      Message: 'Failed to load worklists',
    }),
    label: actions.receiveWorklists,
  });
};

const setWorklistsCountNull = () => ({
  type: actions.receiveWorklistsCount,
  payload: null,
});

const getAllWLCount = (allWlFilters) => {
  const url = Base_URL + Get_All_WorklistsCount_URL;
  function setAllDynamicWLCount(data) {
    return {
      type: actions.setAllDynamicWLCount,
      payload: data,
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      allWlFilters: JSON.stringify(allWlFilters),
    },
    onSuccess: (data) => setAllDynamicWLCount(data),
    onFailure: (err, data) => {
      console.log('Error occured while fetching record count of some/all worklists ', err);
      return setAllDynamicWLCount(data);
    },
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Worklist record count',
      Message: 'Error occured while fetching record count of some/all worklists',
    }),
    label: actions.setAllDynamicWLCount,
  });
};

const fetchDynamicWLCount = (searchFilters, wlID, wlName) => {
  const url = Base_URL + Get_MaterialList_SearchCount_URL;
  function setDynamicWLCount(data) {
    return {
      type: actions.setDynamicWLCount,
      payload: { data, wlID },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SearchFilters: JSON.parse(searchFilters),
    },
    onSuccess: (data) => setDynamicWLCount(data),
    onFailure: () => console.log('Error occured fetching worklist count'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Worklist record count',
      Message: `Error occured while fetching record count of worklist ${wlName}`,
    }),
    label: actions.setDynamicWLCount,
  });
};

const clearWorklistDetails = () => ({
  type: actions.receiveWorklistDetails,
  payload: null,
});

const deleteWorklists = (selectedWorklistItems, userEmail, mode) => {
  const url = Base_URL + Delete_Worklist_URL;
  return apiAction({
    url,
    method: 'POST',
    data: {
      SelectedWorklistItems: selectedWorklistItems,
      Mode: mode,
    },
    onSuccess: () => getWorklists(userEmail, true),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Delete Worklist',
      Message: 'The selected worklists were deleted successfully',
    },
    onFailure: () => console.log('Error occured on deleting the worklist'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Delete Worklist',
      Message: 'Something went wrong. Please try again',
    }),
    label: actions.deleteWorklistStore,
  });
};

const getStaticWLs = (userID, mode) => {
  const url = `${Base_URL + StaticWorklists}?UserID=${userID}&Mode=${mode}`;
  let type;
  if (mode === listModes.mmList) {
    type = actions.storeMMStaticWLs;
  } else type = actions.storeStaticWLs;
  function storeStaticWLs(data) {
    return {
      type,
      payload: data,
    };
  }
  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeStaticWLs,
    onFailure: () => console.log('Error occured fetching static worklist'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Static Worklists',
      Message: 'Failed to load static worklists',
    }),
    label: type,
  });
};

const getStaticWLByID = (worklistID, mode) => {
  const url = `${Base_URL + SharedStaticWorklist}?WorklistID=${worklistID}&Mode=${mode}`;
  return apiAction({
    url,
    method: 'GET',
    onSuccess: setSharedSWL,
    onFailure: () => console.log('Error occured fetching shared static worklist'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Static Worklists',
      Message: 'Failed to load shared static worklist',
    }),
    label: actions.getSharedSWL,
  });
};

const getRelevantStaticWLs = (userID, plant, material) => {
  const url = `${Base_URL + RelevantStaticWorklists}?UserID=${userID}&PlantID=${plant}&MaterialID=${material}`;
  function storeRelevantStaticWLs(data) {
    return {
      type: actions.storeRelevantStaticWLs,
      payload: {
        plant,
        material,
        worklists: data,
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeRelevantStaticWLs,
    onFailure: () => console.log('Error occured fetching relevant static worklists'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Relevant Static Worklists',
      Message: 'Failed to load relevant static worklists',
    }),
    label: actions.storeRelevantStaticWLs,
  });
};

const createStaticWL = (metadata, columnData = {}, worklistItems = [], mode) => {
  const url = Base_URL + StaticWorklist;
  const {
    userID, worklistID, noOfRecords, worklistName, userEmail, worklistDescription,
  } = metadata;
  const {
    selectedColumns, sortColumn, sortDirection,
  } = columnData;
  const wlItems = mode === listModes.mmList ? worklistItems?.join('&') ?? null : worklistItems;
  return apiAction({
    url,
    method: 'POST',
    data: {
      UserID: userID,
      userEmail: userEmail || null,
      WorklistID: worklistID,
      NoOfRecords: noOfRecords,
      WorklistName: worklistName,
      SelectedColumns: selectedColumns ? JSON.stringify(selectedColumns) : null,
      SortColumn: sortColumn || null,
      SortDirection: sortDirection || null,
      WorklistItems: wlItems || null,
      WorklistDescription: worklistDescription || null,
      Mode: mode,
    },
    onSuccess: () => getStaticWLs(userID, mode),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Static Worklist created',
      Message: `Static Worklist named ${worklistName} has been created`,
    },
    onFailure: () => console.log('Error occured when creating worklist'),
    onFailureNotification: (errorMsg) => creationErrorNotification(errorMsg, worklistName),
    label: actions.createStaticWL,
  });
};

const saveSharedSWL = (metadata, mode) => {
  const url = Base_URL + SaveSharedSWL;
  const {
    userID, worklistID, worklistName, userEmail, worklistDescription,
  } = metadata;
  return apiAction({
    url,
    method: 'POST',
    data: {
      UserID: userID,
      userEmail,
      WorklistID: worklistID,
      WorklistName: worklistName,
      WorklistDescription: worklistDescription || null,
      Mode: mode,
    },
    onSuccess: () => getStaticWLs(userID, mode),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Static Worklist created',
      Message: `Static Worklist named ${worklistName} has been created`,
    },
    onFailure: () => console.log('Error occured when creating worklist'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Shared Worklist save failure',
      Message: `Something went wrong. Unable to save shared worklist ${worklistName}.`,
    }),
    label: actions.saveSharedStaticWL,
  });
};

const addToStaticWL = (metadata, worklistItems) => {
  const url = `${Base_URL + StaticWorklist}/${SWLAddItems}`;
  const {
    userID, worklistID, worklistName,
  } = metadata;
  return apiAction({
    url,
    method: 'PATCH',
    data: {
      UserID: userID,
      WorklistID: worklistID,
      WorklistItems: worklistItems || null,
    },
    onSuccess: () => getStaticWLs(userID, listModes.matList),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Item(s) added',
      Message: `Item(s) added to Static Worklist named ${worklistName}`,
    },
    onFailure: () => console.log('Error occured when adding items to worklist'),
    onFailureNotification: (errorMsg) => creationErrorNotification(errorMsg, worklistName),
    label: actions.addToStaticWL,
  });
};

const clearStaticWorklist = (mode) => ({
  type: (mode === listModes.mmList) ? actions.storeMMStaticWL : actions.storeStaticWL,
  payload: [],
});

const getStaticWL = (worklistID, sortColumn, sortDirection, noOfRows, offset, mode) => {
  const url = Base_URL + StaticWorklist;
  let type;
  if (mode === listModes.mmList) type = actions.storeMMStaticWL;
  else type = actions.storeStaticWL;
  function storeStaticWL(data) {
    return {
      type,
      payload: data,
    };
  }
  function addtoStaticWLStore(data) {
    return {
      type: mode === listModes.mmList ? actions.addToMMStaticWLStore : actions.addToStaticWLStore,
      payload: data,
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      worklistID,
      sortColumn,
      sortDirection,
      noOfRows,
      offset,
      Mode: mode,
    },
    onSuccess: offset === 0 ? storeStaticWL : addtoStaticWLStore,
    onFailure: () => console.log('Error occured fetching Static Worklist details'),
    onFailureNotification: (errorMsg) => creationErrorNotification(errorMsg, '<static worklist>'),
  });
};

const updateMMStaticWLContent = (metadata, worklistItems, addToList) => {
  const url = `${Base_URL + StaticWorklist}/${MMSWLUpdateItems}`;
  const {
    userID, worklistID, worklistName,
  } = metadata;
  const title = addToList ? 'Item(s) added' : 'Item(s) removed';
  const successsMsg = addToList ? 'added to' : 'removed from';
  const errMsg = addToList ? 'adding' : 'removing';
  return apiAction({
    url,
    method: 'PATCH',
    data: {
      UserID: userID,
      WorklistID: worklistID,
      WorklistItems: worklistItems || null,
    },
    onSuccess: () => getStaticWL(
      worklistID, null, null, NUMBER_OF_RECORDS_TO_FETCH, 0, listModes.mmList,
    ),
    onSuccessNotification: {
      Type: 'success',
      Title: title,
      Message: `Item(s) ${successsMsg} Material master Static Worklist named ${worklistName}`,
    },
    onFailure: () => console.log(`Error occured when ${errMsg} items to Material Masterworklist`),
    onFailureNotification: (errorMsg) => creationErrorNotification(errorMsg, worklistName),
    label: actions.addToMMStaticWL,
  });
};

const allMaterialStaticWL = (
  metadata,
  columnData = {},
  materialViewState,
  mode,
  recordsToAdd = null,
) => {
  let url = Base_URL;
  if (mode === listModes.mmList) url += Get_MaterialMaster_URL;
  else url += Get_MaterialList_URL;

  const {
    noOfRecords, worklistName, create,
  } = metadata;

  const formatWLItems = (items) => {
    const allItems = [];
    if (items?.length) {
      if (mode === listModes.mmList) {
        const tempItems = items?.map((item) => item?.MATERIAL_TYPE_SAP_ID);
        if (create) return tempItems;
        return getMMSelectedItems(tempItems, metadata.wlItems);
      }
      items?.forEach((item) => allItems.push({
        plantID: item?.PLANT_FACILITY_SAP_ID,
        materialID: item?.MATERIAL_TYPE_SAP_ID,
      }));
    }
    return allItems;
  };

  return apiAction({
    url,
    method: 'POST',
    data: {
      SortColumn: materialViewState.sortColumn?.length > 0
        ? materialViewState.sortColumn : undefined,
      SortDirection: materialViewState.sortDirection?.length > 0
        ? materialViewState.sortDirection : undefined,
      SearchFilters: materialViewState.searchFilters,
      NoOfRecords: recordsToAdd || noOfRecords,
    },
    onSuccess: (data) => {
      if (create) {
        return createStaticWL(metadata, columnData, formatWLItems(data), mode);
      }
      if (mode === listModes.mmList) {
        return updateMMStaticWLContent(metadata, formatWLItems(data), true);
      }
      return addToStaticWL(metadata, formatWLItems(data), mode);
    },
    onFailure: () => console.log('Error occured when creating worklist'),
    onFailureNotification: (errorMsg) => creationErrorNotification(errorMsg, worklistName),
    label: actions.allMaterialStaticWL,
  });
};

const removeFromStaticWL = (metadata, worklistItems, mode) => {
  const url = Base_URL + StaticWorklist;
  const {
    userID, worklistID, worklistName,
  } = metadata;
  return apiAction({
    url,
    method: 'DELETE',
    data: {
      UserID: userID,
      WorklistID: worklistID,
      WorklistItems: worklistItems || null,
      Mode: mode,
    },
    onSuccess: () => getStaticWL(worklistID, null, null, 50, 0, mode),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Item(s) removed',
      Message: `Item(s) removed from Static Worklist named ${worklistName}`,
    },
    onFailure: () => console.log('Error occured when removing items from worklist'),
    onFailureNotification: (errorMsg) => creationErrorNotification(errorMsg, worklistName),
    label: actions.removeFromStaticWL,
  });
};

const deleteStaticWLs = (worklists, userID, mode) => {
  const url = Base_URL + StaticWorklists;
  return apiAction({
    url,
    method: 'DELETE',
    data: {
      Worklists: worklists,
      Mode: mode,
    },
    onSuccess: () => getStaticWLs(userID, mode),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Worklists(s) deleted',
      Message: 'The selected worklist(s) were deleted',
    },
    onFailure: () => console.log('Error occured when deleting worklists'),
    label: actions.deleteStaticWLs,
  });
};

const setStaticWLView = (WorklistID, UserID, SelectedColumns, Mode) => {
  const url = Base_URL + StaticWorklist;
  return apiAction({
    url,
    method: 'PATCH',
    data: {
      WorklistID,
      UserID,
      SelectedColumns: JSON.stringify(SelectedColumns),
      Mode,
    },
    onSuccess: () => getStaticWLs(UserID),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Worklist updated',
      Message: 'Worklist View updated',
    },
    onFailure: () => console.log('Error occured when setting Worklist View'),
    label: actions.setStaticWLView,
  });
};

export default {
  createWorklistAction,
  getWorklists,
  fetchWorklistDetails,
  updateWorklistAction,
  deleteWorklists,
  getAllWLCount,
  fetchDynamicWLCount,
  setWorklistsCountNull,
  clearWorklistDetails,
  allMaterialStaticWL,
  createStaticWL,
  getStaticWLs,
  getRelevantStaticWLs,
  addToStaticWL,
  updateMMStaticWLContent,
  removeFromStaticWL,
  clearStaticWorklist,
  getStaticWL,
  getStaticWLByID,
  deleteStaticWLs,
  setStaticWLView,
  nullRowCount,
  nullRowCountAll,
  setSharedSWL,
  saveSharedSWL,
};
