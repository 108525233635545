import React, { useState, useEffect } from 'react';
import './App.css';
import './Styles/CommonStyles.css';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProtectedRoute from './Routers/ProtectedRoute';
import allActions from './actions';
import { showNotification } from './actions/utilities/Notifications/NotificationActions';
import MainMenu from './components/Menu/Menu';
import AppBar from './components/Menu/AppBar';
import NotificationMsg from './components/Common/NotificationMsg';
import {
  Dashboard,
  DynamicWorklists,
  StaticWorklists,
  MaterialListView,
  MaterialMasterView,
  MaterialDetails,
  FeatureTimeline,
  NewStockAddition,
  Scenario,
  Config,
  TestCenter,
  MonetaryImpactSummary,
  // eslint-disable-next-line no-unused-vars
  MassUpdate,
} from './components/Views';
import Settings from './components/Views/Settings';
import WorklistContainer from './components/Views/MaterialListView/WorklistContainer';
import StaticWLContainer from './components/Views/MaterialListView/StaticWLContainer';
import MMStaticWLContainer from './components/Views/MaterialMaster/MMStaticWLContainer';
import MMWorklistContainer from './components/Views/MaterialMaster/MMWorklistContainer';
import AllSimResultsContainer from './components/Views/SimulationViews/AllSimResultsContainer';
import downloadFile from './components/Common/DownloadFunctions';
import { appInsights } from './api/appInsights';
import { listModes } from './components/Common/GlobalConstants';
import ConfigViewContainer from './components/Views/Config/ConfigViewContainer';
import ConfirmModal from './components/Common/ConfirmModal';
import SelectedItemsContext from './contexts/selectedItemsContext';
import useSelectedItems from './hooks/contextHooks/useSelectedItems';
import ErrorComponent from './components/Views/ErrorComponent';

const { Sider } = Layout;

const App = ({ msalInstance }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authState);
  const settingsLoaded = useSelector((state) => state.commonState.loaded?.userSettings);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const jobID = useSelector((state) => state.commonState.CSVID);
  const csv = useSelector((state) => state.commonState.CSV);
  const warningText = useSelector((state) => state.commonState.warningText);
  const outdated = useSelector((state) => state.commonState.outdated);
  const plants = useSelector((state) => state.commonState.plants);
  const history = useHistory();

  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  // to limit access, uncomment the code below and import userHasAuthenticatedRole
  // const [hasAccess, setHasAccess] = useState(false);
  // useEffect(() => {
  //   if (authState?.user?.idTokenClaims?.roles) {
  //     const userHasAuthenticatedRole = hasAuthenticatedRole(
  //       authState?.user.idTokenClaims.roles,
  //       process.env.REACT_APP_CONFIG_ACCESS,
  //     );
  //     setHasAccess(userHasAuthenticatedRole);
  //   }
  // }, [authState?.user?.idTokenClaims, authState?.user?.idTokenClaims?.roles]);

  const hasAccess = true;

  const onCollapse = (collapsed) => {
    setMenuCollapsed(collapsed);
  };

  useEffect(() => {
    dispatch(allActions.CommonActions.getPlants());
  }, []);

  useEffect(() => {
    // load resources into redux on start, but wait for
    // plants so the token is received
    if (plants) {
      dispatch(allActions.CommonActions.getCompanyCodes());
      dispatch(allActions.CommonActions.getColumnMapper());
      dispatch(allActions.MaterialListActions.fetchMaterialColumns());
      dispatch(allActions.MaterialListActions.getDropdownValues());
      dispatch(allActions.MMActions.fetchMaterialColumns());
      dispatch(allActions.CommonActions.getPlantGroups());
      dispatch(allActions.CommonActions.getConfigTable());
      dispatch(allActions.CommonActions.getWarnings());
      dispatch(allActions.CommonActions.getOutdated());
    }
  }, [plants]);

  useEffect(() => {
    if (!outdated || outdated === 'false') {
      setShowPopup(false);
    } else if (outdated === 'true') {
      const hasSeenPopup = localStorage.getItem('seenPopUp');
      setShowPopup(!hasSeenPopup);
    }
  }, [outdated]);

  const hidePopup = () => {
    localStorage.setItem('seenPopUp', true);
    setShowPopup(false);
  };

  useEffect(() => {
    let intervalID;
    if (jobID) {
      if (csv?.error) {
        dispatch(showNotification(
          {
            Type: 'error',
            Title: 'CSV Retrieval Failure',
            Message: 'Failed to generate the exported file',
          },
        ));
        clearInterval(intervalID);
        dispatch(allActions.CommonActions.clearCSV());
        return undefined;
      }
      if (csv?.done) {
        downloadFile(csv.url);
        clearInterval(intervalID);
        dispatch(allActions.CommonActions.clearCSV());
      } else if (!csv?.done) {
        intervalID = setInterval(() => {
          dispatch(allActions.CommonActions.getCSV(jobID));
        }, 2000);
      }
    }
    return () => {
      clearInterval(intervalID);
    };
  }, [jobID, csv]);

  useEffect(() => {
    if (!authState?.user?.account) {
      const account = msalInstance.getActiveAccount();
      if (!account) return;
      dispatch(allActions.AuthActions.updateAccountData(account));
    }
  }, [authState?.user]);

  useEffect(() => {
    if (authState.user?.uniqueId) {
      dispatch(allActions.CommonActions.setLoading('USER_SETTINGS'));
      dispatch(
        allActions.CommonActions.getAllUserSettings(authState.user.uniqueId),
      );
      dispatch(allActions.CommonActions.getWidgetViews());
      dispatch(allActions.CommonActions.getCurrencyList());
      dispatch(allActions.WorkListActions.getStaticWLs(authState.user.uniqueId, listModes.matList));
      dispatch(allActions.WorkListActions.getStaticWLs(authState.user.uniqueId, listModes.mmList));
      appInsights?.setAuthenticatedUserContext(authState.user.uniqueId);
      appInsights?.trackPageView();
      dispatch(allActions.CommonActions.trackUniqueIds(authState.user.uniqueId));
      dispatch(allActions.CommonActions.getESStatus());
      const intervalES = setInterval(() => {
        dispatch(allActions.CommonActions.getESStatus());
      }, 60000);
      return () => clearInterval(intervalES);
    }
  }, [authState?.user?.uniqueId]);

  useEffect(() => {
    if (settingsLoaded) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(
        !!userSettings?.USE_DEFAULTS_GLOBALLY,
      ));
    }
  }, [settingsLoaded]);

  const { origin } = window;
  const isProd = !origin.includes('localhost') && !origin.includes('dev') && !origin.includes('acc');

  return (
    <Router>
      {
        hasAccess ? (
          <SelectedItemsContext.Provider value={useSelectedItems()}>
            <Layout>
              {
              warningText && (
                <Alert
                  message={<div style={{ fontSize: '22px' }}>{warningText}</div>}
                  type="warning"
                  icon={<WarningOutlined style={{ fontSize: '28px' }} />}
                  showIcon
                  banner
                  closable
                />
              )
            }

              <ConfirmModal
                title="Outdated data"
                visible={showPopup}
                message="IOTA data is from Saturday 2023-04-22, due to SAP maintenance Sunday 2023-04-23"
                hideFooter
                onCancel={hidePopup}
              />

              <NotificationMsg />
              <Layout>

                <div className="mainLayout">
                  <MainMenu isProd={isProd} />
                  <div className="innerLayout">
                    <AppBar isProd={isProd} />
                    <div className="content">
                      <Switch onClick={onCollapse} onKeyDown={onCollapse}>
                        <Route path="/" exact>
                          <Redirect to="/dashboard" />
                        </Route>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/simulations/:runID?" component={AllSimResultsContainer} />
                        <Route path="/simulation/new-stock" component={NewStockAddition} />
                        <Route path="/simulation/scenario" component={Scenario} />
                        <Route path="/material-details/:plant/:material" component={MaterialDetails} />
                        <Route path="/feature-timeline" component={FeatureTimeline} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/material-list" component={MaterialListView} />
                        <Route path="/material-master" component={MaterialMasterView} exact />
                        <Route path="/work-lists" component={DynamicWorklists} />
                        <Route path="/work-list/:worklistID?" component={WorklistContainer} />
                        <Route path="/material-master/work-list/:worklistID?" component={MMWorklistContainer} />
                        <Route path="/mass-update" component={MassUpdate} exact />
                        <Route path="/static-work-lists" component={StaticWorklists} />
                        <Route path="/static-work-list/:worklistID?" component={StaticWLContainer} />
                        <Route path="/material-master/static-work-list/:worklistID?" component={MMStaticWLContainer} />
                        <Route path="/monetary-impact" component={MonetaryImpactSummary} />
                        <Route path="/config-view" component={ConfigViewContainer} />

                        <ProtectedRoute path="/config" requiredRoles={['SuperUser']} redirectPath="/dashboard">
                          <Config />
                        </ProtectedRoute>
                        <ProtectedRoute path="/test-center" requiredRoles={['SuperUser']} redirectPath="/dashboard">
                          <TestCenter />
                        </ProtectedRoute>
                      </Switch>
                    </div>
                  </div>
                </div>
              </Layout>
            </Layout>
          </SelectedItemsContext.Provider>
        ) : (
          <Switch onClick={onCollapse} onKeyDown={onCollapse}>
            <Route path="/error" component={ErrorComponent} />
            <Redirect from="*" to="/error" />
          </Switch>
        )
      }
    </Router>
  );
};
export default App;

App.propTypes = {
  msalInstance: PropTypes.objectOf(PropTypes.any).isRequired,
};
