import React, { useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Empty, Input, Space, Table, Tooltip,
} from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../Common/Loading';
import { SIM_RETENTION_PERIOD, SIM_TYPES } from '../../Common/GlobalConstants';
import getStatusDisplay from '../../Common/Simulation/SimulationStatus';
import ScenarioResult from './ScenarioResult';
import MaterialSimResult from '../../Common/Simulation/MaterialSimResult';
import './AllSimResults.css';
import SimInputDisplay from './SimInputDisplay';

const AllSimResults = ({
  allSimJobs,
  simResults,
  expandSimJob,
  updateSelectedItems,
  deleteSelected,
  selectedItems,
  deleteSpecific,
}) => {
  const intialState = {
    searchText: '',
    searchedColumn: '',
  };
  const [columnsState, setColumnsState] = useState(intialState);
  const [height, setHeight] = useState(0);
  const { runID } = useParams();
  const [expandedRowKeys, setExpandedRows] = useState();

  useEffect(() => {
    if (runID) setExpandedRows([runID]);
  }, [runID]);

  useLayoutEffect(() => {
    setHeight(document.getElementById('pageContainer')?.clientHeight);
    const resizeListener = () => {
      setHeight(document.getElementById('pageContainer')?.clientHeight);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setColumnsState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setColumnsState({ searchText: '' });
  };

  const getColumnSearchProps = (dataIndex, colName, search) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            columnsState.searchInput = node;
          }}
          placeholder={`Search ${colName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (search
      ? <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      : <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => columnsState.searchInput.select(), 100);
      }
    },
  });

  const getTimeUntilDeletion = (timeStamp) => {
    const endTime = new Date(timeStamp).getTime() + SIM_RETENTION_PERIOD * 1000;
    return moment(endTime);
  };

  const getColumns = () => {
    const columnHeaders = [
      {
        key: 'RunID',
        dataIndex: 'RunID',
        title: 'Sim ID',
        width: 115,
        sorter: (a, b) => a.RunID.localeCompare(b.RunID),
        ...getColumnSearchProps('RunID', 'Sim ID', true),
      },
      {
        key: 'Type',
        dataIndex: 'Type',
        title: 'Type',
        width: 180,
        sorter: (a, b) => a.Type.localeCompare(b.Type),
        filters: Object.values(SIM_TYPES).map((type) => ({ text: type, value: type })),
        onFilter: (value, record) => record.Type.indexOf(value) === 0,
      },
      {
        key: 'Description',
        dataIndex: 'Description',
        title: 'Description',
        width: 350,
        sorter: (a, b) => a.Description && a.Description.localeCompare(b.Description),
        ...getColumnSearchProps('Description', 'Description', true),
      },
      {
        key: 'PlantID',
        dataIndex: 'PlantID',
        title: 'Plant',
        width: 100,
        sorter: (a, b) => a.PlantID && a.PlantID.localeCompare(b.PlantID),
        ...getColumnSearchProps('PlantID', 'Plant', true),
      },
      {
        key: 'MaterialID',
        dataIndex: 'MaterialID',
        title: 'Material',
        width: 150,
        sorter: (a, b) => a.MaterialID && a.MaterialID.localeCompare(b.MaterialID),
        render: (text, record) => (
          record.Type === SIM_TYPES.SINGLE_MATERIAL
            ? <Link to={`/material-details/${record.PlantID}/${record.MaterialID}`}>{text}</Link>
            : text
        ),
        ...getColumnSearchProps('MaterialID', 'Material', true),
      },
      // {
      //   key: 'ValuesChanged',
      //   dataIndex: 'ValuesChanged',
      //   title: 'Values changed',
      //   width: 150,
      //   ...getColumnSearchProps('RunID', 'Sim ID', true),
      // },
      {
        key: 'RunTimeStamp',
        dataIndex: 'RunTimeStamp',
        title: 'Start time',
        defaultSortOrder: 'descend',
        width: 160,
        render: (RunTimeStamp) => RunTimeStamp && moment(RunTimeStamp).format('DD-MM-YYYY HH:mm'),
        sorter: (a, b) => new Date(a.RunTimeStamp) - new Date(b.RunTimeStamp),
      },
      {
        key: 'TimeUntilDeletion',
        dataIndex: 'RunTimeStamp',
        title: 'Automatic deletion',
        width: 160,
        render: (RunTimeStamp) => RunTimeStamp && (
          <Tooltip placement="bottom" title={getTimeUntilDeletion(RunTimeStamp).format('DD-MM-YYYY')}>
            {getTimeUntilDeletion(RunTimeStamp).fromNow()}
          </Tooltip>
        ),
      },
      {
        key: 'delete',
        dataIndex: 'delete',
        title: (
          <Button onClick={() => deleteSelected()} disabled={selectedItems?.length < 1}>
            Delete Selected
          </Button>
        ),
        width: 140,
        render: (_text, record) => (
          <Button size="small" onClick={(e) => deleteSpecific(e, record.RunID)}>
            Delete
          </Button>
        ),
      },
      {
        key: 'collapse',
        dataIndex: 'collapse',
        title: (
          <Button
            disabled={!expandedRowKeys || expandedRowKeys.length === 0}
            onClick={() => setExpandedRows([])}
          >
            Collapse All
          </Button>
        ),
        width: 120,
      },
    ];
    return columnHeaders;
  };

  const columns = getColumns();

  const status = (results) => (
    <>
      {results?.status
        ? getStatusDisplay(results)
        : <Empty description="No simulation data found" />}
    </>
  );

  const renderResults = (record) => (simResults[record.RunID]?.results
    ? (() => {
      switch (record.Type) {
        case SIM_TYPES.SINGLE_MATERIAL:
          return (
            <div>
              <SimInputDisplay simID={record.RunID} />
              <MaterialSimResult
                simData={simResults?.[record.RunID]?.results}
                // TODO should we pass in plant paramter?
              />
            </div>
          );
        case SIM_TYPES.SCENARIO:
          return (
            <div>
              <SimInputDisplay simID={record.RunID} />
              <ScenarioResult
                metaData={allSimJobs?.[record.RunID]}
                simulatedData={simResults?.[record.RunID]?.results}
              />
            </div>
          );
        default: return null;
      }
    })()
    : status(simResults[record.RunID]));

  const expandRender = (record) => (simResults?.[record.RunID]
    ? renderResults(record)
    : <Loading text="Loading simulation" spinning />);

  return (
    <Table
      className="centralViewTable"
      columns={columns}
      dataSource={Object.values(allSimJobs)}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => expandRender(record),
        onExpand: (expanded, record) => expandSimJob(expanded, record),
        onExpandedRowsChange: (expandedRows) => setExpandedRows(expandedRows),
        expandedRowKeys,
        expandRowByClick: true,
        columnWidth: 10,
        fixed: true,
      }}
      rowSelection={{
        type: 'checkbox',
        onChange: (_selectedRowKeys, selectedRows) => {
          updateSelectedItems(selectedRows);
        },
        columnWidth: 10,
        fixed: true,
      }}
      size="small"
      scroll={{ y: height - 100, x: true }}
      sticky
    />
  );
};

export default AllSimResults;

AllSimResults.defaultProps = {
  allSimJobs: {},
  simResults: {},
  selectedItems: [],
};

AllSimResults.propTypes = {
  allSimJobs: PropTypes.objectOf(PropTypes.object),
  simResults: PropTypes.objectOf(PropTypes.object),
  expandSimJob: PropTypes.func.isRequired,
  updateSelectedItems: PropTypes.func.isRequired,
  deleteSelected: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any),
  deleteSpecific: PropTypes.func.isRequired,
};
