/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Loading from '../../Common/Loading';
import { modes } from './MaterialListFunctions';
import { USE_WORKLIST_VIEW, listModes } from '../../Common/GlobalConstants';
import MaterialListContainer from './MaterialListContainer';
import allActions from '../../../actions';

function StaticWLContainer() {
  const { worklistID } = useParams();
  const worklists = useSelector((state) => state.worklistState.staticWorklists);
  const sharedSWL = useSelector((state) => state.worklistState.sharedStaticWL);
  const materialViewStore = useSelector((state) => state.materialState.localMaterialViewStore);
  const displayHeaderCells = useSelector((state) => state.materialState.displayHeaderCells);
  const [worklistDetails, setWorklistDetails] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const [viewState, setViewState] = useState({
    sortColumn: null,
    sortDirection: null,
    headerCells: null,
  });

  useEffect(() => {
    dispatch(allActions.WorkListActions.setSharedSWL(null));
  }, []);

  useEffect(() => {
    if (sharedSWL && sharedSWL.WORKLIST_ID === worklistID) {
      setWorklistDetails(sharedSWL);
      if (sharedSWL.SelectedColumns?.length > 0) {
        dispatch(allActions.MaterialListActions.selectView(USE_WORKLIST_VIEW));
      }
    }
  }, [sharedSWL]);

  useEffect(() => {
    if (worklists?.length > 0 && worklistID) {
      const worklist = worklists.find((e) => e.WORKLIST_ID === worklistID);
      if (worklist) {
        setWorklistDetails(worklist);
        if (worklist.SelectedColumns?.length > 0) {
          dispatch(allActions.MaterialListActions.selectView(USE_WORKLIST_VIEW));
        }
      } else if (sharedSWL?.WORKLIST_ID !== worklistID) {
        dispatch(allActions.WorkListActions.getStaticWLByID(worklistID, listModes.matList));
      }
    }
  }, [worklists, worklistID]);

  useEffect(() => {
    let tempHeader = [];
    if (worklistDetails?.SelectedColumns) {
      tempHeader = JSON.parse(worklistDetails.SelectedColumns);
    }
    if (history.location?.state?.prevPath === 'matDetails') {
      if (displayHeaderCells?.length) {
        tempHeader = _.cloneDeep(displayHeaderCells);
      }
      setViewState({
        sortColumn: materialViewStore?.sortColumn,
        sortDirection: materialViewStore?.sortDirection,
        headerCells: tempHeader,
      });
    } else {
      if (!tempHeader.length && displayHeaderCells?.length) tempHeader = _.clone(displayHeaderCells);
      setViewState({
        sortColumn: worklistDetails?.SortColumn,
        sortDirection: worklistDetails?.sortDirection,
        headerCells: tempHeader,
      });
    }
  }, [worklistDetails, displayHeaderCells]);

  return (
    worklistDetails
      ? (
        <MaterialListContainer
          worklistID={worklistID}
          worklistDetails={{
            WorklistID: worklistDetails?.WORKLIST_ID,
            WorklistName: worklistDetails?.WorklistName,
            WorklistDescription: worklistDetails?.WorklistDescription,
            CreatedDate: worklistDetails?.CreatedDate,
            ModifiedDate: worklistDetails?.ModifiedDate,
            NoOfRecords: worklistDetails?.NoOfRecords,
          }}
          headerCells={viewState.headerCells}
          sortColumn={viewState.sortColumn}
          sortDirection={viewState.sortDirection}
          mode={modes.STATIC}
        />
      )
      : <Loading text="Loading Worklist" />
  );
}

export default StaticWLContainer;
