import _ from 'lodash';
import { formatMaterialNumber } from '../../../../../Common/DateAndNumberFunctions';
import { IMPACT } from '../../../../../Common/GlobalConstants';
import { mrpSettingIsNumber } from './RecommendationHelpers';

const simulatedToRecommendedMapper = {
  ABCIndicator: '',
  MRPGroup: 'CC_MRP_GROUP',
  MRPType: 'CC_MRP_TYPE',
  LotSize: 'CC_LOT_SIZE',
  ReorderPoint: 'CC_ROP_ADJUSTED',
  MinimumLotSize: 'CC_MIN_LOT_SIZE_ADJ',
  MaximumStockLevel: 'CC_MAX_STOCK',
  MaximumLotSize: '',
  RoundingValue: '',
  PlantMaterialStatus: '',
  MRPController: '',
  FixedLotSize: '',
  // CC_LEAD_TIME
  // CC_HOLDING_COST_AS_IS
  // CC_TRANS_COST_AS_IS
  // DISPLAY_REC_IND
};

const cleanValue = (value) => {
  const trimmed = value?.toString().trim() || '';
  if (trimmed === '' || trimmed === '§') {
    return '';
  }
  return value;
};

export const userHasUpdated = (setting) => {
  const current = cleanValue(setting.current);
  const userDefined = cleanValue(setting.userDefined);
  if (current === userDefined) return false;
  if (mrpSettingIsNumber(setting.key)) {
    const inputAsString = `${userDefined}`;
    const currentAsString = `${current}`;
    const inputAsNumber = Number.parseFloat(inputAsString?.replace(',', '.'), 10);
    const currentAsNumber = Number.parseFloat(currentAsString?.replace(',', '.'), 10);
    if (!Number.isNaN(inputAsNumber) || !Number.isNaN(currentAsNumber)) {
      return inputAsNumber !== currentAsNumber;
    }
  }
  return !_.isEqual(current, userDefined);
};

export const createIOTAPayload = (plant, material, dataSource, user, MRP = false, monetaryImpact = 0, monetaryImpactType = '-', massUpdate = false) => {
  const changeArray = [];
  if (massUpdate) {
    dataSource.forEach((item) => {
      changeArray.push({
        changeType: item.changeType,
        oldValue: cleanValue(item.oldValue),
        newValue: cleanValue(item.newValue),
      });
    });
  } else {
    dataSource.forEach((source) => {
      if (userHasUpdated(source)) {
        changeArray.push({
          changeType: source.key,
          oldValue: cleanValue(source.current),
          newValue: cleanValue(source.userDefined),
        });
      }
    });
  }

  const impact = Math.abs(Number.parseFloat(monetaryImpact)) || 0;
  const changeObject = {
    plant,
    material,
    changedBy: user?.uniqueId,
    changedByEmail: user?.account?.username,
    changes: changeArray,
    MRP,
    monetaryImpact: monetaryImpactType === IMPACT.invest
      ? -(impact)
      : impact,
    monetaryImpactType,
  };
  return changeObject;
};

export const createIOTAPayloadMassMRPnoChanges = (impacts, user) => {
  const changes = [];
  impacts.forEach((impact) => {
    const change = createIOTAPayload(impact.plant, impact.material, [], user, true, impact.impact, impact.monImpType, true);
    change.changes = [{ changeType: 'No MRP changes', oldValue: '', newValue: '' }];
    changes.push(change);
  });
  return changes;
};

export const createIOTAPayloadMassMRP = (changeObject, user) => {
  const { to_Mrp: changes, monetaryImpacts: impacts, changelog } = changeObject;
  const listOfChanges = changes?.map((change) => {
    const { Plant: plant, MaterialNumber } = change;
    const material = formatMaterialNumber(MaterialNumber);
    const monetaryImpact = impacts.find((i) => i.plant === plant
      && i.material.toString() === material.toString())?.impact || 0;
    let monetaryImpactType;
    if (monetaryImpact > 0 || monetaryImpact < 0) {
      monetaryImpactType = (monetaryImpact < 0 ? IMPACT.invest : IMPACT.savings);
    } else {
      monetaryImpactType = IMPACT.noImp;
    }
    const relevantChangelog = changelog.filter(
      (x) => (x.Plant === plant && x.MaterialNumber === MaterialNumber),
    );
    return createIOTAPayload(
      plant, material, relevantChangelog, user, true, monetaryImpact, monetaryImpactType, true,
    );
  });
  return listOfChanges;
};

const replaceCommasInNumbers = (dataSource) => dataSource.map((setting) => {
  if (mrpSettingIsNumber(setting.key)) {
    return { ...setting, userDefined: setting.userDefined?.toString().replace(',', '.') };
  }
  return setting;
});

const setCompatibleValue = (setting) => {
  const value = setting?.userDefined?.toString().trim() || '';
  if (mrpSettingIsNumber(setting.key)) {
    return (value === '' || value === '§') ? 0 : value;
  }
  return (value === '' || value === '§') ? '' : value;
};

export const createSapPayload = (dataSource, mrpSettings, plant, material) => {
  const formattedDataSource = replaceCommasInNumbers(dataSource);
  const payload = {};
  formattedDataSource.forEach((setting) => {
    if (userHasUpdated(setting)) {
      const value = setCompatibleValue(setting);
      payload[setting.key] = `${value}`;
    }
  });
  // Always update Margin Key if currently not 001
  const mrp = mrpSettings.find((x) => x.Plant === plant
    && formatMaterialNumber(x.MaterialNumber) === material);
  if (!_.isEmpty(payload) && mrp?.SchedulingMarginKeyFloats !== '001') {
    payload.SchedulingMarginKeyFloats = '001';
  }
  return payload;
};

export const addSimulatedToRecTable = (recTableData, simulatedValues) => {
  const newRecTableData = _.clone(recTableData);
  recTableData.forEach((value, index) => {
    newRecTableData[index].simulated = simulatedValues[simulatedToRecommendedMapper[value.key]];
  });
  return newRecTableData;
};
